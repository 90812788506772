export const ELECTRICITY_USAGE = [1400, 2300, 3000, 3700, 4400, 5100, 5800, 6500, 7200, 7900];

export const PEOPLE_LIMIT = {
  min: 1,
  max: ELECTRICITY_USAGE.length,
  default: 1,
  step: 1,
};

export const ELECTRICITY_USAGE_HEAT = [1400, 2300, 3000, 3700, 4500, 5400, 6300, 7200, 8100, 9000];

export const PEOPLE_LIMIT_HEAT = {
  min: 1,
  max: ELECTRICITY_USAGE_HEAT.length,
  step: 1,
};

export const gasUsagePerSquareMeter = 150;

export const squareMeterLimit = {
  min: 5,
  max: 500,
  default: 30,
  step: 5,
};

export const usageLimitGas = {
  min: 1,
  max: 99999,
};

export const usageLimitElectricity = {
  min: 1,
  max: 49999,
};

export const mileageLimit = {
  min: 1,
  max: Number.MAX_SAFE_INTEGER,
  default: 13000,
  step: 100,
};

export const homeChargingPartLimit = {
  default: 85,
};

export const carEfficiencyLimit = {
  default: 0.25,
};

export const carUsage = {
  default: 2800,
};
