'use client';

// DataDog logger is only allowed in the client

const stage = process.env.NEXT_PUBLIC_STAGE;
const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '';
const service = process.env.NEXT_PUBLIC_DATADOG_SERVICE_NAME || 'frontend-next';
const version = process.env.NEXT_PUBLIC_VERSION ?? 'local';

let initialized = false;

export const ddLogger = () => {
  if (!clientToken || typeof window === 'undefined') {
    return console; // fallback
  }

  import('@datadog/browser-logs').then(({ datadogLogs }) => {
    if (!initialized) {
      initialized = true;
      datadogLogs.init({
        clientToken,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service,
        env: stage,
        version,
        beforeSend: ({ error, message, status }) => {
          if (status === 'error' && message?.includes(`Fetch error POST ${process.env.NEXT_PUBLIC_GRAPHQL_SERVER}`)) {
            return false;
          }

          if (status === 'error' && error?.stack?.includes('hubspot.com/web-interactives-embed.js')) {
            return false;
          }

          if (['frontend-website', 'frontend-checkout-b2c'].includes(service) && message?.includes('[OPTIMIZELY]')) {
            return false;
          }

          return true;
        },
      });
    }
  });

  return {
    debug: (message: string, params?: object, error?: Error) => {
      import('@datadog/browser-logs').then(({ datadogLogs }) => {
        datadogLogs.logger.debug(message, params, error);
      });
    },
    info: (message: string, params?: object, error?: Error) => {
      import('@datadog/browser-logs').then(({ datadogLogs }) => {
        datadogLogs.logger.info(message, params, error);
      });
    },
    log: (message: string, params?: object) => {
      import('@datadog/browser-logs').then(({ datadogLogs }) => {
        datadogLogs.logger.log(message, params);
      });
    },
    warn: (message: string, params?: object, error?: Error) => {
      import('@datadog/browser-logs').then(({ datadogLogs }) => {
        datadogLogs.logger.warn(message, params, error);
      });
    },
    error: (message: string, params?: object, error?: Error) => {
      import('@datadog/browser-logs').then(({ datadogLogs }) => {
        datadogLogs.logger.error(message, params, error);
      });
    },
  };
};
