'use client';

import { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Box, Loader } from '@lichtblick/ui-components';

import { LoadingContainer } from './OSuccessPageContent.styles';
import { OSuccessPageContentIcons } from './OSuccessPageContentIcons';

export const OSuccessPageContent: FC = () => (
  <>
    <OSuccessPageContentIcons />
    <Box backgroundColor={Colors.FadedGray} data-testid="success-page-content">
      <div id="success-page-content">
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      </div>
    </Box>
  </>
);
