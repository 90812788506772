'use client';

import Link from '@lichtblick/link';
import styled, { createGlobalStyle } from '@lichtblick/styled';
import { BaseStyles, Spacing } from '@lichtblick/theme';

export const GlobalStyle = createGlobalStyle`
  html[amp-version],
  html[amp-version] body {
    /* stylelint-disable-next-line meowtec/no-px */
    font-size: 16px;
  }
  ${BaseStyles};
`;

export const Header = styled.header`
  left: 0.25rem;
  position: fixed;
  top: 0.5rem;
  z-index: 10;
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  height: 3rem;
  margin: 0 0.4rem;
  padding: ${Spacing.Xxs};
  width: 3rem;
`;

export const Footer = styled.footer`
  bottom: 1rem;
  position: fixed;
  right: 2rem;
`;
