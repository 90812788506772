/* eslint-disable no-console */

// add our properties and format as JSON
const formatLogs = (message: string, params?: object) =>
  JSON.stringify({
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE_NAME ?? 'frontend-next',
    env: process.env.NEXT_PUBLIC_STAGE ?? 'local',
    version: process.env.NEXT_PUBLIC_VERSION ?? 'local',
    message,
    ...params,
  });

/**
 * The server logger is a wrapper for the console.log that formats the log in a JSON format that DataDog likes
 */
export const serverLogger = {
  debug: (message: string, params?: object, error?: Error) => console.debug(formatLogs(message, params), error),
  info: (message: string, params?: object, error?: Error) => console.info(formatLogs(message, params), error),
  log: (message: string, params?: object) => console.log(formatLogs(message, params)),
  warn: (message: string, params?: object, error?: Error) => console.warn(formatLogs(message, params), error),
  error: (message: string, params?: object, error?: Error) => console.error(formatLogs(message, params), error),
};
