'use client';

import { FC } from 'react';

import { Text } from '@lichtblick/photon/atoms/text/text';
import { Footnote } from '@lichtblick/photon/molecules/footnote/footnote';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { useOptimizelyFeatureFlag } from '../../hooks/use-optimizely-decision';
import { MFootnoteType } from '../../types/storyblok';

export type MFootnoteProps = MFootnoteType & {
  index: number;
  theme?: 'light' | 'dark';
};

const elementMappers: Partial<ElementMappers> = {
  p: ({ children }) => (
    <Text renderAs="p" size="xs">
      {children}
    </Text>
  ),
};

export const MFootnote: FC<MFootnoteProps> = ({ featureFlag, index, text, theme }) => {
  const footNote = useOptimizelyFeatureFlag(featureFlag);

  if (featureFlag && !footNote) {
    return null;
  }

  return (
    <Footnote color={theme === 'light' ? 'Black' : 'White'} index={index.toString()}>
      <RichText elementMappers={elementMappers} richText={text} />
    </Footnote>
  );
};
