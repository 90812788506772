import { Spacing } from '@lichtblick/theme/constants/spacing';

// contentful spacings need to be mapped to the spacings from the theme

const spacingMap: Record<string, Spacing | 0> = {
  none: 0,
  xxs: Spacing.Xxs,
  xs: Spacing.Xs,
  s: Spacing.S,
  m: Spacing.M,
  l: Spacing.L,
  xl: Spacing.Xl,
  xxl: Spacing.Xxl,
  '3xl': Spacing['3Xl'],
};

export const getSpacing = (spacing?: string | null) => (spacing ? spacingMap[spacing] : undefined);
