import classNames from 'classnames';
import { RefObject, useCallback, useEffect, useState } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { formatNumber } from '@lichtblick/localization';
import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { Stepper } from '@lichtblick/photon/molecules/stepper/stepper';
import { Modal } from '@lichtblick/photon/organisms/modal/modal';
import { useTracker } from '@lichtblick/tracker';

import styles from './calculator-usage-input.module.scss';
import { carEfficiencyLimit, homeChargingPartLimit, mileageLimit } from './constants';

type ChargingUsageInputProps = {
  onChange: (value: string, mileage: number) => void;
  usageRef: RefObject<HTMLInputElement>;
};

const calculateUsage = (mileage: number) =>
  Math.round((carEfficiencyLimit.default * (homeChargingPartLimit.default / 100) * mileage) / 100) * 100;

export const ChargingUsageInput: React.FC<ChargingUsageInputProps> = ({ onChange, usageRef }) => {
  const { trackCustomEvent } = useTracker();
  const [mileage, setMileage] = useState<number>(mileageLimit.default);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleStepperChange = useCallback(
    (mileage: number | undefined) => {
      if (mileage) {
        setMileage(mileage);

        if (usageRef.current) {
          usageRef.current.value = calculateUsage(mileage).toString();
          onChange(usageRef.current.value, mileage);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMileage],
  );

  useEffect(() => {
    handleStepperChange(13000);
  }, [handleStepperChange]);

  return (
    <div>
      <Stepper
        label="Fahrleistung in km/Jahr"
        min={mileageLimit.min}
        name="mileage"
        onChange={handleStepperChange}
        onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
        step={mileageLimit.step}
        value={mileage}
      />
      <div className={styles['calculator-input-row']}>
        <Text size="xs">
          Dein Verbrauch{/* */}
          <input
            aria-invalid={usageRef.current?.checkValidity() === false}
            aria-label="Verbrauch in kWh/Jahr"
            className={classNames(
              styles['calculator-usage-input'],
              usageRef.current?.checkValidity() === false && styles['calculator-usage-input-error'],
            )}
            defaultValue={calculateUsage(mileageLimit.default)}
            name="carUsage"
            onChange={(event) => onChange(event.target.value, mileage)}
            onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
            onInvalid={(e) => e.preventDefault()}
            ref={usageRef}
            required
            type="number"
          />
          {/* */}
          kWh/Jahr
        </Text>
        <TextCTA
          ariaLabel="Informationen zur Berechnung des Autoverbrauchs zeigen"
          as="button"
          icon={<InfoIconXs />}
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
        />
      </div>

      <Modal
        footerContent={
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsInfoModalOpen(false)}>
            Ok, verstanden
          </TextCTA>
        }
        isOpen={isInfoModalOpen}
        onPointerDownOutside={() => setIsInfoModalOpen(false)}
        title="So berechnet LichtBlick den Verbrauchsanteil für euer E-Auto"
      >
        <div className={styles['modal-content']}>
          <Headline renderAs={'h2'} size="s">
            So berechnet LichtBlick den Verbrauchsanteil für euer E-Auto
          </Headline>
          <Text renderAs={'p'}>
            Solltet ihr euren Jahresverbruach für das Laden des E-Autos zu Hause kennen, könnt ihr es direkt eintragen.
            Besitzt ihr noch keine längeren Erfahrungswerte, schätzen wir den Verbrauch für euch: Bis zu{' '}
            {homeChargingPartLimit.default} % der E-Auto-Ladevorgänge finden im privaten Umfeld statt, beispielsweise am
            eigenen Stellplatz. Für den durchschnittlichen Stromverbrauch gehen wir von{' '}
            {formatNumber(carEfficiencyLimit.default)} kWh/km aus. Der tatsächliche Verbrauch hängt – neben dem
            Automodell – auch von der Fahrweise und den Wetterbedingungen ab. Daraus ergibt sich die Rechnung:
          </Text>
          <Text renderAs={'p'}>
            Verbrauch = Fahrleistung pro Jahr x {homeChargingPartLimit.default} % zu Hause Laden x{' '}
            {formatNumber(carEfficiencyLimit.default)} kWh/km.
          </Text>
          <Text renderAs={'p'}>Dieser Wert wird verwendet, wenn ihr die Jahreskilometer für das E-Auto angebt.</Text>
        </div>
      </Modal>
    </div>
  );
};
