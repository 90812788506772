'use client';

import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { Chip } from '@lichtblick/photon/atoms/chip/chip';
import { Text } from '@lichtblick/photon/atoms/text/text';
import radioButtonStyles from '@lichtblick/photon/molecules/radio/radio-button.module.scss';
import { AccordionTrigger } from '@lichtblick/photon/organisms/accordion/accordion';
import { Colors } from '@lichtblick/photon/types/colors';
import { useTracker } from '@lichtblick/tracker';

import styles from './calculator-layout-trigger.module.scss';

type CalculatorLayoutTriggerProps = {
  actionTriggerDisabled: boolean;
  chip?: string;
  className?: string;
  color: Colors;
  headline?: string;
  icon: ReactNode;
};

export const CalculatorLayoutTrigger: FC<CalculatorLayoutTriggerProps> = ({
  actionTriggerDisabled,
  chip,
  className,
  color,
  headline,
  icon,
}) => {
  const { trackCustomEvent } = useTracker();
  const Trigger = actionTriggerDisabled ? 'div' : AccordionTrigger;

  return (
    <Trigger
      className={classNames(styles['accordion-trigger'], styles[`accordion-trigger-bg-${color}`], className)}
      {...(actionTriggerDisabled && { 'data-state': 'open' })}
      {...(!actionTriggerDisabled && {
        onClick: () => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true),
      })}
    >
      {!actionTriggerDisabled && (
        <span
          className={classNames(
            styles['radio-button'],
            radioButtonStyles['radio-button'],
            radioButtonStyles['state-default'],
          )}
        />
      )}
      {headline && (
        <Text bold renderAs="span" size="l">
          {headline}
        </Text>
      )}
      {Boolean(chip) && (
        <Chip backgroundColor="White" className={styles.chip}>
          {chip}
        </Chip>
      )}
      {icon}
    </Trigger>
  );
};
