import { FC, PropsWithChildren } from 'react';

import styles from './radio-group.module.scss';

import { Text } from '../../atoms/text/text';
import { FieldFooter } from '../field-footer/field-footer';

export type RadioGroupProps = PropsWithChildren<{
  className?: string;
  'data-testid'?: string;
  errorMessage?: string;
  label?: string;
}>;

export const RadioGroup: FC<RadioGroupProps> = ({ children, errorMessage, label, ...wrapperProps }) => (
  <fieldset className={styles.wrapper} {...wrapperProps}>
    {Boolean(label) && (
      <Text className={styles.legend} renderAs="legend">
        {label}
      </Text>
    )}
    {children}
    {Boolean(errorMessage) && <FieldFooter label={errorMessage} state="error" />}
  </fieldset>
);
