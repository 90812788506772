'use client';

import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { Icon } from '@lichtblick/icons';
import { useTracker } from '@lichtblick/tracker';

import styles from './o-toast.module.scss';
import { isDayOfService, isTimeOfService } from './OToast.helpers';

import { OToastType } from '../../types/storyblok';

export const SectionWithDisplayHandling: FC<OToastType & PropsWithChildren> = ({
  children,
  office_hour_end,
  office_hour_start,
  out_of_office,
  time_to_show,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const { trackCustomEvent } = useTracker();

  const trackEvent = useCallback(() => {
    trackCustomEvent({
      eventName: 'hotline_teaser_impression',
    });
  }, [trackCustomEvent]);

  useEffect(() => {
    const toastTimeout = (time_to_show === '0' ? 0 : Number(time_to_show) || 120) * 1000;
    const shouldShowToast =
      isDayOfService(out_of_office?.value ?? []) &&
      isTimeOfService(
        office_hour_start ? Number(office_hour_start) : 8,
        office_hour_end ? Number(office_hour_end) : 16,
      );

    if (shouldShowToast) {
      setTimeout(() => {
        setIsHidden(false);
        trackEvent();
      }, toastTimeout);
    }
  }, [office_hour_end, office_hour_start, out_of_office, time_to_show, trackEvent]);

  return isHidden ? null : (
    <section className={styles.section} data-testid="toast">
      <div className={styles.wrapper}>
        <button
          aria-label="Schließen"
          className={styles['close-button']}
          onClick={() => setIsHidden(true)}
          type="button"
        >
          <Icon name="CloseIconS" />
        </button>
        {children}
      </div>
    </section>
  );
};
