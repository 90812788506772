import classNames from 'classnames';
import { ChangeEventHandler, useRef, forwardRef } from 'react';

import { CrossCircleIconXs } from '@lichtblick/icons/svg/cross-circle/cross-circle-xs';
import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';

import { doesValueMatchSearch } from './combo-box-input.helpers';
import styles from './combo-box-input.module.scss';

import { DropDown, DropDownHandle } from '../../atoms/drop-down/drop-down';
import { FieldFooter } from '../field-footer/field-footer';
import { Label } from '../label/label';
import { TextfieldProps } from '../textfield/textfield';

export type ComboBoxInputOption = {
  keywords?: string[];
  value: string;
};

export type ComboBoxInputProps = TextfieldProps & {
  options: ComboBoxInputOption[];
};

export const ComboBoxInput = forwardRef<HTMLInputElement, ComboBoxInputProps>(
  (
    {
      className,
      'data-testid': testId,
      disabled,
      icon,
      id,
      label,
      name,
      onChange,
      options,
      statusLabel,
      statusType = 'default',
      value,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef = useRef<DropDownHandle | null>(null);
    const inputId = id || name;
    const labelId = inputId && `${inputId}_label`;
    const statusId = inputId && `${inputId}_validationMessage`;
    const searchValue = value?.toString();
    const filteredOptions = options.filter(
      ({ keywords, value }) =>
        doesValueMatchSearch(value, searchValue) || (keywords && doesValueMatchSearch(keywords.join(), searchValue)),
    );

    const setValue: ChangeEventHandler<HTMLInputElement> = (event) => {
      dropdownRef.current?.open();
      onChange?.(event);
    };

    const focusInput = () => inputRef.current?.focus();

    const dispatchChange = (value: string) => {
      onChange?.({ target: { value, name } } as any);
      dropdownRef.current?.close();
    };

    const openDropdown = () => {
      focusInput();
      dropdownRef.current?.open();
    };

    const clearInput = () => dispatchChange('');

    return (
      <div className={classNames('combo-box-input', styles.wrapper, className)}>
        <div className={styles['label-wrapper']}>
          {Boolean(label) && (
            <Label bold htmlFor={inputId} id={labelId}>
              {label}
            </Label>
          )}
        </div>
        <DropDown
          aria-disabled={disabled}
          className={classNames(
            styles['input-row'],
            disabled && styles['input-row-disabled'],
            statusType !== 'default' && styles[`input-row-${statusType}`],
          )}
          onClose={focusInput}
          onOptionSelect={dispatchChange}
          options={filteredOptions}
          ref={dropdownRef}
          variant="input"
        >
          <input
            {...props}
            aria-describedby={statusId}
            aria-invalid={statusType === 'error'}
            aria-labelledby={labelId}
            className={styles.input}
            data-testid={testId || inputId}
            disabled={disabled}
            id={inputId}
            name={name}
            onChange={setValue}
            ref={(instance) => {
              if (typeof ref === 'function') {
                ref(instance);
              } else if (ref) {
                ref.current = instance;
              }

              inputRef.current = instance;
            }}
            value={value}
          />
          {!disabled && (
            <div className={styles.slot}>
              {Boolean(value) && (
                // using `<div>` instead of `<button>` to avoid changing `:focus-within` unless `tabIndex` is explicitly set
                <div aria-hidden className={styles['icon-wrapper']} onClick={clearInput}>
                  <CrossCircleIconXs />
                </div>
              )}
              <div aria-hidden className={styles['icon-wrapper']} onClick={openDropdown}>
                <SearchIconXs />
              </div>
            </div>
          )}
        </DropDown>
        {Boolean(statusLabel) && <FieldFooter icon={icon} id={statusId} label={statusLabel} state={statusType} />}
      </div>
    );
  },
);
