import { logger } from '@lichtblick/logger';

import { RichText } from './RichText';

import { SettingsType } from '../types/storyblok';

export type MaintenanceContent = {
  buttonLink?: string | null;
  buttonText?: string | null;
  headline?: string | null;
  text?: string | null;
};

type MaintenanceProductContent = {
  text: MaintenanceContent;
};

export type MaintenanceProductsConfig = Partial<Record<string, MaintenanceProductContent | null>>;

// Contentful delivers the keys separated by a hyphen, but the AllInOneCalculator and the Forms expect camel case.
const maintenanceProductMap: Record<string, string> = {
  'form-b2b-electricity': 'formB2bElectricity',
  'form-b2b-gas': 'formB2bGas',
  'home-charging': 'homeCharging',
  'on-road-charging': 'onRoadCharging',
};

// Contentful delivers the key "home-charging", but the AllInOneCalculator expects "homeCharging".
const mapProductName = (product: string) =>
  product in maintenanceProductMap ? maintenanceProductMap[product] : product;

export const createMaintenanceProductsConfig = async (
  products: SettingsType['maintenanceProducts'],
): Promise<MaintenanceProductsConfig> => {
  const productEntries = await Promise.all(
    (products ?? []).map(async (collectionItem) => {
      const ReactDOMServer = (await import('react-dom/server')).default;

      const { maintenanceText, product } = collectionItem || {};
      const { button, text } = maintenanceText?.[0] || {};

      if (!product) {
        logger.warn(`Unable to create maintenance config for product ${product}.`);

        return null;
      }

      const markup = text ? <RichText richText={text} /> : <></>;

      return {
        [mapProductName(product)]: {
          text: {
            buttonText: button?.[0]?.text,
            buttonLink: button?.[0]?.linkUrl,
            text: ReactDOMServer.renderToStaticMarkup(<>{markup}</>),
          },
        },
      };
    }),
  );

  return productEntries.reduce((acc, entry) => {
    if (entry) {
      Object.assign(acc, entry);
    }

    return acc;
  }, {} as MaintenanceProductsConfig);
};
