'use client';

import {
  AccordionItemProps as AccordionItemType,
  AccordionTriggerProps as AccordionTriggerType,
  AccordionContentProps as AccordionContentType,
  Header,
  Item,
  Root,
  Trigger,
  Content,
} from '@radix-ui/react-accordion';
import classNames from 'classnames';

import styles from './accordion.module.scss';

export const AccordionRoot = Root;

export type AccordionItemProps = AccordionItemType &
  React.RefAttributes<HTMLDivElement> & {
    className?: string;
  };

export const AccordionItem: React.FC<AccordionItemProps> = ({ className, ...props }) => (
  <Item {...props} className={classNames('accordion-item', styles.item, className)} />
);

export const AccordionHeader = Header;

export type AccordionTriggerProps = AccordionTriggerType &
  React.RefAttributes<HTMLButtonElement> & {
    className?: string;
  };

export const AccordionTrigger: React.FC<AccordionTriggerProps> = ({ className, ...props }) => (
  <Trigger {...props} className={classNames('accordion-trigger', styles.trigger, className)} />
);

export type AccordionContentProps = AccordionContentType &
  React.RefAttributes<HTMLDivElement> & {
    className?: string;
    isAnimationDisabled?: boolean;
  };

export const AccordionContent: React.FC<AccordionContentProps> = ({ className, isAnimationDisabled, ...props }) => (
  <Content
    {...props}
    className={classNames(
      'accordion-content',
      styles.content,
      !isAnimationDisabled && styles['content-animation'],
      className,
    )}
  />
);
