'use client';

import { FC } from 'react';

import { useTemplateTracker } from '../../hooks/useTemplateTracker';

export const TemplateTracker: FC<{
  contentGroup?: string;
  contentGroup2?: string;
  contentGroup3?: string;
  metaTitle?: string;
  trackingPageArea?: string;
}> = ({ contentGroup, contentGroup2, contentGroup3, metaTitle, trackingPageArea }) => {
  useTemplateTracker({
    contentGroup,
    contentGroup2,
    contentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: 'referral',
    pageTitle: metaTitle ?? '',
  });

  return null;
};
