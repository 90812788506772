import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';

import { SmallAndAbove } from '@lichtblick/theme/utils/breakpoints';

import styles from './image.module.scss';

export type ImageProps = HTMLAttributes<HTMLPictureElement> & {
  className?: string;
  description: string;
  url: {
    desktop?: string;
    mobile: string;
  };
};

export const Image: FC<ImageProps> = ({ className, description, url, ...pictureProps }) => (
  <picture {...pictureProps} className={classNames('image', className)}>
    {url.desktop && <source className={styles.image} media={SmallAndAbove} srcSet={url.desktop} />}
    <img alt={description} className={styles.image} loading="lazy" src={url.mobile} />
  </picture>
);
