import classNames from 'classnames';
import { RefObject, useCallback, useEffect, useState } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { formatNumber } from '@lichtblick/localization';
import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { Stepper } from '@lichtblick/photon/molecules/stepper/stepper';
import { Modal } from '@lichtblick/photon/organisms/modal/modal';
import { useTracker } from '@lichtblick/tracker';

import styles from './calculator-usage-input.module.scss';
import { ELECTRICITY_USAGE, PEOPLE_LIMIT } from './constants';

type ElectrictyUsageInputProps = {
  children: JSX.Element;
  onChange: (value: string, peopleCount: number) => void;
  usageRef: RefObject<HTMLInputElement>;
};

export const ElectrictyUsageInput: React.FC<ElectrictyUsageInputProps> = ({ children, onChange, usageRef }) => {
  const { trackCustomEvent } = useTracker();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [numberOfPeople, setNumberOfPeople] = useState<number>(PEOPLE_LIMIT.min);

  const handleStepperChange = useCallback(
    (value: number | undefined) => {
      if (value) {
        const cleanedValue = Math.max(PEOPLE_LIMIT.min, Math.min(value, PEOPLE_LIMIT.max));

        setNumberOfPeople(cleanedValue);

        if (usageRef.current) {
          usageRef.current.value = ELECTRICITY_USAGE[cleanedValue - 1].toString();
          onChange(usageRef.current.value, numberOfPeople);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNumberOfPeople],
  );

  useEffect(() => {
    handleStepperChange(PEOPLE_LIMIT.min);
  }, [handleStepperChange]);

  return (
    <div>
      <Stepper
        label="Anzahl der Personen"
        max={PEOPLE_LIMIT.max}
        min={PEOPLE_LIMIT.min}
        name="people"
        onChange={handleStepperChange}
        onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
        step={PEOPLE_LIMIT.step}
        value={numberOfPeople}
      />
      <div className={styles['calculator-input-row']}>
        <Text size="xs">
          Dein Verbrauch{/* */}
          <input
            aria-invalid={usageRef.current?.checkValidity() === false}
            aria-label="Verbrauch in kWh/Jahr"
            className={classNames(
              styles['calculator-usage-input'],
              usageRef.current?.checkValidity() === false && styles['calculator-usage-input-error'],
            )}
            defaultValue={ELECTRICITY_USAGE[0]}
            name="strom"
            onChange={(event) => onChange(event.target.value, numberOfPeople)}
            onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
            onInvalid={(e) => e.preventDefault()}
            ref={usageRef}
            required
            type="number"
          />
          {/* */}
          kWh/Jahr
        </Text>
        <TextCTA
          ariaLabel="Informationen zur Berechnung des Stromverbrauchs zeigen"
          as="button"
          icon={<InfoIconXs />}
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
        />
      </div>
      <Modal
        footerContent={
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsInfoModalOpen(false)}>
            Ok, verstanden
          </TextCTA>
        }
        isOpen={isInfoModalOpen}
        onPointerDownOutside={() => setIsInfoModalOpen(false)}
        title="So berechnet LichtBlick den Stromverbrauch"
      >
        <div className={styles['modal-content']}>
          <Headline renderAs={'h2'} size="s">
            So berechnet LichtBlick den Stromverbrauch
          </Headline>
          <Text renderAs={'p'}>
            Natürlich wisst nur ihr genau, was ihr verbraucht und könnt es deswegen auch direkt eintragen. Aus über 20
            Jahren Erfahrung als Ökostromanbieter kann LichtBlick aber auch ziemlich gut schätzen: Im Schnitt braucht
            eine Person ca. {formatNumber(ELECTRICITY_USAGE[0])} kWh. Bei 2 Personen kommt man auf{' '}
            {ELECTRICITY_USAGE[1]} kWh – jede weitere Person erhöht den Verbrauch jährlich um 700 kWh. Diese Werte
            werden also verwendet, wenn ihr die Personenzahl im Haushalt angebt.
          </Text>
        </div>
      </Modal>
      {children}
    </div>
  );
};
