import styled from '@lichtblick/styled';
import {
  alpha,
  BorderRadius,
  BorderWidth,
  Colors,
  DurationAndEasing,
  MediaMediumAndAbove,
  Opacities,
  Shades,
  Spacing,
  StateColors,
} from '@lichtblick/theme';
import { Headline, sharedInputRowStyles, sharedInputStyles } from '@lichtblick/ui-components';

export const MainContainer = styled.div`
  display: grid;
  padding: 4rem 0;

  ${MediaMediumAndAbove} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Badge = styled.div`
  border-radius: 50rem;
  background-color: ${Colors.Orange};
  padding: 0 1rem;
  color: ${Shades.Aqua700};
  font-style: italic;
  flex: 0;
  text-align: center;
`;

export const FormContainer = styled.div`
  flex: 1;
  padding-bottom: ${Spacing.Xl};

  form,
  .hs-dependent-field {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 1rem;
    width: 100%;
  }

  .field {
    width: 100%;
  }

  ul {
    list-style-type: none;
  }

  fieldset {
    width: 100%;
    max-width: 100% !important;
    display: flex;
    justify-content: stretch;
    gap: 1rem;
  }

  label:not(.hs-form-booleancheckbox-display):not(.hs-error-msg) {
    font-weight: 600;
    font-size: 1rem;
    line-height: 170%;
    margin-bottom: 0.25rem;
    display: block;
  }

  .input:has(> input:not([type='checkbox']):not([type='hidden'])),
  select,
  textarea {
    ${sharedInputRowStyles.base}
    border-radius: ${BorderRadius.S};
    border: 2px solid ${Colors.Black};
    display: flex;
    height: 2.6875rem;
    width: 100% !important;

    &:has(> input.error) {
      ${sharedInputRowStyles.error}
    }

    input:not([type='checkbox']):not([type='hidden']) {
      ${sharedInputStyles.base}
    }
  }

  select,
  textarea {
    width: 100%;
    padding-left: ${Spacing.Xs};
    padding-right: ${Spacing.Xs};
    font-family: inherit;
    font-size: 1rem;
    color: ${Colors.Black};
    background-color: ${Colors.White};
  }

  textarea {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .input:has(> select),
  .input:has(> textarea) {
    margin-right: 0 !important;
  }

  .hs-error-msg,
  .hs-error-msgs {
    font-size: 0.75rem;
    color: ${StateColors.Error};
  }

  .hs-form-booleancheckbox-display {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;

    > span {
      margin-left: 0 !important;
    }
  }

  .hs-richtext {
    font-size: 0.75rem;
    line-height: 170%;

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  input[type='submit'] {
    border: none;
    border-radius: ${Spacing.L};
    transition: 0.1s;
    font-size: 1rem;
    font-weight: 600;
    flex-grow: 0;
    line-height: 1.7;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: ${Colors.Orange};
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      background-color: color-mix(in srgb, currentColor 11%, ${Colors.Orange});
    }

    &:active {
      outline: none;
      transform: scale(0.98);
    }
  }

  input[type='checkbox'] {
    margin-top: 5px;
    align-items: center;
    appearance: none;
    border: ${BorderWidth.Input} solid black;
    border-radius: ${BorderRadius.Xxs};
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    display: flex;
    flex-shrink: 0;
    height: ${Spacing.Xs};
    justify-content: center;
    outline: none;
    position: relative;
    width: ${Spacing.Xs} !important;
    z-index: 0;

    /* Focus indicator */
    &::before {
      border: 0.375rem solid ${alpha(Colors.Orange, Opacities.Light)};
      border-radius: ${BorderRadius.Xs};
      content: '';
      height: 100%;
      opacity: 0;
      position: absolute;
      transition: opacity ${DurationAndEasing.SmallIntro};
      width: 100%;
      z-index: -1;
    }

    /* The checkmark */
    &::after {
      background-image: url("data:image/svg+xml;utf8,<svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.47339 4.85233L4.09485 6.86554L8.57571 1.13208' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      opacity: 0;
      transition: opacity ${DurationAndEasing.SmallIntro};
      width: 100%;
    }

    &:focus-visible::before {
      opacity: 1;
    }

    &:checked {
      background: ${Colors.Orange};

      &::after {
        opacity: 1;
      }
    }
  }
`;

export const AquaHeadline = styled(Headline)`
  color: ${Shades.Aqua200};
`;

export const RichTextWrapper = styled.div`
  color: ${Colors.Orange};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${Shades.Aqua200};
  }
`;
