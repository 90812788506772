'use client';

import { FC, VideoHTMLAttributes } from 'react';

import styled, { css } from '@lichtblick/styled';
import { SmallAndAbove } from '@lichtblick/theme';

const videoStyles = css`
  max-width: 100%;
`;

const VideoMobile = styled.video`
  ${videoStyles}

  @media ${SmallAndAbove} {
    display: none;
  }
`;

const VideoDesktop = styled.video`
  ${videoStyles}

  @media not ${SmallAndAbove} {
    display: none;
  }
`;

const getSourceProps = (videoUrls: string[]) =>
  videoUrls.filter(Boolean).map((src) => ({
    key: src,
    src,
    type: `video/${src.split('.').pop()?.toLowerCase()}`,
  }));

export type VideoProps = VideoHTMLAttributes<HTMLVideoElement> & {
  description: string;
  posterUrl?: {
    desktop?: string;
    mobile?: string;
  };
  urls: {
    desktop?: string[];
    mobile: string[];
  };
};

export const Video: FC<VideoProps> = ({ description, posterUrl, urls, ...videoProps }) => {
  const sourcesMobile = getSourceProps(urls.mobile);
  const sourcesDesktop = getSourceProps(urls.desktop ?? []).concat(sourcesMobile);

  return (
    <>
      <VideoMobile poster={posterUrl?.mobile} preload="none" {...videoProps}>
        {sourcesMobile.map(({ key, src, type }) => (
          <source key={key} src={src} type={type} />
        ))}
        {description}
      </VideoMobile>
      <VideoDesktop poster={posterUrl?.desktop || posterUrl?.mobile} preload="none" {...videoProps}>
        {sourcesDesktop.map(({ key, src, type }) => (
          <source key={key} src={src} type={type} />
        ))}
        {description}
      </VideoDesktop>
    </>
  );
};
