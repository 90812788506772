'use client';

import { Text, TextSizeClass } from '@lichtblick/ui-components';

import { StyledBulletItem } from './ABulletItem.styles';

import { RichText } from '../../helpers/RichText';
import { RichTextDocType } from '../../types/storyblok';

export type BulletItemProps = {
  richText?: RichTextDocType;
  size?: TextSizeClass;
};

export const ABulletItem = ({ richText, size = 'S' }: BulletItemProps) => {
  const content = richText && <RichText richText={richText} />;

  if (!content) {
    return null;
  }

  return (
    <StyledBulletItem>
      <Text size={size}>{content}</Text>
    </StyledBulletItem>
  );
};
