'use client';

import Script from 'next/script';
import { FC } from 'react';

export const HubSpotFormScript: FC = () => (
  <Script
    id="hubspot-contact-form-script"
    onReady={() => {
      (window as any).hbspt.forms.create({
        region: 'eu1',
        portalId: '143379264',
        formId: 'ebf80417-cba2-44f7-a01e-2c17c6bad7da',
        target: '#hubspot-contact-form',
      });
    }}
    src="//js-eu1.hsforms.net/forms/embed/v2.js"
    type="text/javascript"
  />
);
