import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import styles from './list.module.scss';

type ListProps = PropsWithChildren & {
  className?: string;
};

export const Li: FC<ListProps> = ({ children, className }) => (
  <li className={classNames('li', styles.li, className)}>{children}</li>
);

export const Ul: FC<ListProps> = ({ children, className }) => (
  <ul className={classNames('ul', styles.ul, className)}>{children}</ul>
);

export const Ol: FC<ListProps> = ({ children, className }) => (
  <ol className={classNames('ol', styles.ol, className)}>{children}</ol>
);
