'use client';

import { SolarIconM } from '@lichtblick/icons/svg/solar/solar-m';
import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { useTracker } from '@lichtblick/tracker';

import { CalculatorLayout } from './calculator-layout';
import styles from './calculator-layout.module.scss';

import { buildUrl } from '../../helpers/links';
import { SolarTeaserType } from '../../types';

type SolarTeaserProps = SolarTeaserType & Pick<CalculatorLayout, 'isActionTriggerDisabled'>;

export const SolarTeaser: React.FC<SolarTeaserProps> = ({
  _uid,
  buttonLink,
  buttonText,
  chip,
  headline,
  isActionTriggerDisabled,
  text,
}) => {
  const { trackCustomEvent } = useTracker();

  return (
    <CalculatorLayout
      chip={chip}
      headerColor="DarkOrange"
      headline={headline}
      icon={<SolarIconM />}
      id={_uid}
      isActionTriggerDisabled={isActionTriggerDisabled}
    >
      <div className={styles['calculator-content']}>
        <Text>{text}</Text>
        <BoxCTA
          as="a"
          data-testid="solar-teaser-button"
          href={buildUrl(buttonLink) ?? ''}
          onClick={() =>
            trackCustomEvent({
              eventName: 'tariff_calculator_show_tariffs',
              customProps: {
                product: 'Solar',
              },
            })
          }
          wide
        >
          {buttonText}
        </BoxCTA>
      </div>
    </CalculatorLayout>
  );
};
