import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { buildImageSrcSet, generateImageSources, getImageDimensions, isSvgImage } from './Picture.helpers';
import styles from './Picture.module.scss';

import { AssetType } from '../../types/storyblok';

type PictureProps = ComponentProps<'picture'> & {
  asset?: AssetType;
  description: string;
  isBackground?: boolean;
  isFullWidth?: boolean;
  loading?: 'eager' | 'lazy';
  maxWidth?: number;
  params?: Record<string, string>;
};

export const Picture: FC<PictureProps> = ({
  asset,
  description,
  isBackground,
  isFullWidth,
  loading = 'lazy',
  maxWidth = 1024,
  params,
  ...props
}) => {
  if (!asset?.filename) {
    return null;
  }

  const [width, height] = getImageDimensions(asset.filename) ?? [];

  return (
    <picture {...props}>
      {!isSvgImage(asset.filename) && generateImageSources(asset?.filename, maxWidth, params)}
      <img
        alt={asset.alt || description}
        className={classNames(
          styles.image,
          isBackground && styles['image-is-background'],
          isFullWidth && styles['image-is-full-width'],
        )}
        fetchPriority={loading === 'eager' ? 'high' : 'auto'}
        height={height}
        loading={loading}
        src={
          isSvgImage(asset.filename)
            ? asset.filename
            : buildImageSrcSet(asset.filename, Math.min(maxWidth, 360), params)
        }
        width={width}
      />
    </picture>
  );
};
