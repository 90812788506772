import classNames from 'classnames';
import { PropsWithChildren, FC, useRef, FocusEventHandler, ReactNode } from 'react';

import styles from './tooltip.module.scss';

export type TooltipProps = PropsWithChildren<{
  content?: ReactNode;
  isVisible?: boolean;
  offset?: number;
}>;

// We are not using @radix-ui/react-tooltip here, as it is not anchored to the cursor
export const Tooltip: FC<TooltipProps> = ({ children, content, isVisible = true, offset = 16 }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const isFocusEnabled = useRef(true);

  const positionTooltip = (x: number, y: number) => {
    const element = contentRef.current;

    if (element) {
      const { innerHeight, innerWidth } = window;
      const { clientHeight, clientWidth, style } = element;

      // always keep right of cursor, stick at right viewport border
      const offsetX = Math.min(x + offset, innerWidth - clientWidth - offset);
      // display below cursor, but switch to above cursor when hitting bottom viewport border
      const offsetY = y + clientHeight + offset > innerHeight ? y - clientHeight - offset : y + offset;

      style.left = `${offsetX}px`;
      style.top = `${offsetY}px`;
    }
  };

  const showFocusTooltip: FocusEventHandler = ({ target }) => {
    if (isFocusEnabled.current) {
      const { height, left, top, width } = target.getBoundingClientRect();

      positionTooltip(left + width / 2, top + height / 2);
    }
  };

  return (
    <>
      <span
        className={classNames('tooltip-trigger', styles.trigger, isVisible && styles['trigger-visible'])}
        onFocus={showFocusTooltip}
        onMouseEnter={() => (isFocusEnabled.current = false)}
        onMouseLeave={() => (isFocusEnabled.current = true)}
        onMouseMove={({ clientX, clientY }) => positionTooltip(clientX, clientY)}
      >
        {children}
      </span>
      {content && (
        <span className={classNames('tooltip-content', styles['tooltip-content'])} ref={contentRef}>
          {content}
        </span>
      )}
    </>
  );
};
