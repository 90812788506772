import classnames from 'classnames';
import { ComponentProps, forwardRef, PropsWithChildren } from 'react';

import styles from './chip.module.scss';

import { Colors } from '../../types/colors';
import { Text } from '../text/text';

export type ChipProps = PropsWithChildren &
  Omit<ComponentProps<'span'>, 'ref'> & {
    backgroundColor?: Colors;
  };

export const Chip = forwardRef<HTMLSpanElement, ChipProps>(({ backgroundColor = 'Black', children, ...props }, ref) => (
  <span
    ref={ref}
    {...props}
    className={classnames(
      'chip',
      styles.chip,
      styles[`bg-${backgroundColor}`],
      Boolean(props.onClick) && styles.clickable,
      props.className,
    )}
  >
    <Text size="xs">{children}</Text>
  </span>
));
