'use client';

import { FC } from 'react';

import { Colors, Spacing, Breakpoints } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { Container } from './shared';

import { StromwalletTextIntroType } from '../../types';
import { getColor } from '../../utils';
import { MTextIntro } from '../MTextIntro';

export const StromWalletTextIntro: FC<StromwalletTextIntroType> = ({ component: _, theme, ...textIntroProps }) => (
  <Box backgroundColor={getColor(theme) || Colors.FadedGray} pxd={Spacing['3Xl']} pyd={Spacing.Xl} pym={Spacing.Xl}>
    <Container maxWidth={Breakpoints.Small}>
      <MTextIntro component="m-text-intro" {...textIntroProps} />
    </Container>
  </Box>
);
