'use client';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { useConsent, GOOGLE_ANALYTICS_NAME } from '@lichtblick/consent';

// Aklamio referral
const AKLAMIO_VP_CODE = '401817';
const AKLAMIO_PARAM_NAME = 'aid';
const AKLAMIO_SESSION_STORAGE_NAME = 'aklamioId';

// Affiliate
const UTM_PARAMS_PREFIX = 'utm_';
const UTM_PARAMS_SESSION_STORAGE_NAME = 'utmParams';

const VP_CODE_PARAM_NAME = 'vp';
const UVP_CODE_PARAM_NAME = 'uvp';
const VP_CODE_SESSION_STORAGE_NAME = 'vpCode';
const UVP_CODE_SESSION_STORAGE_NAME = 'uvpCode';

export const UrlParamsInitializer: React.FC = () => {
  const searchParams = useSearchParams();
  const googleAnalyticsState = useConsent(GOOGLE_ANALYTICS_NAME);

  useEffect(() => {
    if (googleAnalyticsState) {
      return;
    }

    try {
      sessionStorage.removeItem(UTM_PARAMS_SESSION_STORAGE_NAME);
    } catch (error) {
      /* continue regardless of error */
    }
  }, [googleAnalyticsState]);

  useEffect(() => {
    if (!googleAnalyticsState) {
      return;
    }

    try {
      const utmParams = JSON.parse(sessionStorage.getItem(UTM_PARAMS_SESSION_STORAGE_NAME) ?? '{}');

      for (const key of searchParams.keys()) {
        if (key.toString().indexOf(UTM_PARAMS_PREFIX) === 0) {
          utmParams[key] = searchParams.get(key);
        }
      }

      if (Object.keys(utmParams).length > 0) {
        sessionStorage.setItem(UTM_PARAMS_SESSION_STORAGE_NAME, JSON.stringify(utmParams));
      }
    } catch (error) {
      /* continue regardless of error */
    }
  }, [googleAnalyticsState, searchParams]);

  useEffect(() => {
    const vpCode = searchParams.get(VP_CODE_PARAM_NAME);
    const uvpCode = searchParams.get(UVP_CODE_PARAM_NAME);
    const aid = searchParams.get(AKLAMIO_PARAM_NAME);

    try {
      if (typeof vpCode === 'string') {
        sessionStorage.setItem(VP_CODE_SESSION_STORAGE_NAME, vpCode);

        if (typeof uvpCode === 'string') {
          sessionStorage.setItem(UVP_CODE_SESSION_STORAGE_NAME, uvpCode);
        } else {
          sessionStorage.removeItem(UVP_CODE_SESSION_STORAGE_NAME);
        }
      }

      if (typeof aid === 'string') {
        sessionStorage.setItem(AKLAMIO_SESSION_STORAGE_NAME, aid);
        sessionStorage.setItem(VP_CODE_SESSION_STORAGE_NAME, AKLAMIO_VP_CODE);
        sessionStorage.setItem(UVP_CODE_SESSION_STORAGE_NAME, '');
      }
    } catch (error) {
      /* continue regardless of error */
    }
  }, [searchParams]);

  return null;
};
