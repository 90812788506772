import { Colors as PhotonColors } from '@lichtblick/photon/types/colors';
import { Colors } from '@lichtblick/theme/constants/colors';
import { dark, light } from '@lichtblick/theme/constants/theme';

// TODO: can't we use a function just camelizing the string from contentful instead?
/**
 * contentful returns a string in kebab-case like 'faded-aqua' or 'dark-aqua' for theme colors
 *
 * therefore we need to map the string to the corresponding color from the theme
 * */
export const colorMap = {
  aqua: Colors.Aqua,
  'background-gray': Colors.BackgroundGray,
  berry: Colors.Berry,
  black: Colors.Black,
  'dark-aqua': Colors.DarkAqua,
  'dark-berry': Colors.DarkBerry,
  'dark-gray': Colors.DarkGray,
  'dark-green': Colors.DarkGreen,
  'dark-moss': Colors.DarkMoss,
  'dark-orange': Colors.DarkOrange,
  'dark-red': Colors.DarkRed,
  'dark-smoke': Colors.DarkSmoke,
  'faded-aqua': Colors.FadedAqua,
  'faded-berry': Colors.FadedBerry,
  'faded-gray': Colors.FadedGray,
  'faded-green': Colors.FadedGreen,
  'faded-moss': Colors.FadedMoss,
  'faded-red': Colors.FadedRed,
  'faded-smoke': Colors.FadedSmoke,
  gray: Colors.Gray,
  green: Colors.Green,
  'light-aqua': Colors.LightAqua,
  'light-berry': Colors.LightBerry,
  'light-gray': Colors.LightGray,
  'light-green': Colors.LightGreen,
  'light-moss': Colors.LightMoss,
  'light-red': Colors.LightRed,
  'light-smoke': Colors.LightSmoke,
  moss: Colors.Moss,
  orange: Colors.Orange,
  red: Colors.Red,
  smoke: Colors.Smoke,
  white: Colors.White,
} as const;

export type ColorKey = keyof typeof colorMap;

export const shadeColorMap: Partial<Record<ColorKey, Colors>> = {
  white: Colors.DarkGray,
  'faded-gray': Colors.DarkGray,
  'light-red': Colors.DarkRed,
  'dark-red': Colors.LightRed,
  'light-berry': Colors.DarkBerry,
  'dark-berry': Colors.LightBerry,
  'light-aqua': Colors.DarkAqua,
  'dark-aqua': Colors.LightAqua,
  'light-smoke': Colors.DarkSmoke,
  'dark-smoke': Colors.LightSmoke,
  'light-green': Colors.DarkGreen,
  'dark-green': Colors.LightGreen,
  'light-moss': Colors.DarkMoss,
  'dark-moss': Colors.LightMoss,
  'light-gray': Colors.DarkGray,
  'dark-gray': Colors.LightGray,
} as const;

export const darkColors: Readonly<ColorKey[]> = [
  'aqua',
  'berry',
  'black',
  'dark-aqua',
  'dark-berry',
  'dark-gray',
  'dark-green',
  'dark-moss',
  'dark-red',
  'dark-smoke',
  'green',
  'moss',
  'smoke',
] as const;

export const getColor = (color?: ColorKey | null) => (color ? colorMap[color] : undefined);

export const getTheme = (color?: ColorKey | null) => (color && darkColors.includes(color) ? dark : light);

export const getThemeContrast = (color?: ColorKey | null) => (color && darkColors.includes(color) ? 'black' : 'white');

export const getContrastColor = (color?: ColorKey | null): Extract<ColorKey, 'white' | 'black'> =>
  color && darkColors.includes(color) ? 'white' : 'black';

export const getShadeColor = (color?: ColorKey | null) => (color ? shadeColorMap[color] : undefined);

export const photonColorMap: Record<ColorKey, PhotonColors> = {
  aqua: 'Aqua',
  'background-gray': 'BackgroundGray',
  berry: 'Berry',
  black: 'Black',
  'dark-aqua': 'DarkAqua',
  'dark-berry': 'DarkBerry',
  'dark-gray': 'DarkGray',
  'dark-green': 'DarkGreen',
  'dark-moss': 'DarkMoss',
  'dark-orange': 'DarkOrange',
  'dark-red': 'DarkRed',
  'dark-smoke': 'DarkSmoke',
  'faded-aqua': 'FadedAqua',
  'faded-berry': 'FadedBerry',
  'faded-gray': 'FadedGray',
  'faded-green': 'FadedGreen',
  'faded-moss': 'FadedMoss',
  'faded-red': 'FadedRed',
  'faded-smoke': 'FadedSmoke',
  gray: 'Gray',
  green: 'Green',
  'light-aqua': 'LightAqua',
  'light-berry': 'LightBerry',
  'light-gray': 'LightGray',
  'light-green': 'LightGreen',
  'light-moss': 'LightMoss',
  'light-red': 'LightRed',
  'light-smoke': 'LightSmoke',
  moss: 'Moss',
  orange: 'Orange',
  red: 'Red',
  smoke: 'Smoke',
  white: 'White',
};
