'use client';
import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import Link from '@lichtblick/link';
import { TrackingData, useTracker } from '@lichtblick/tracker';

import styles from './teaser-item.module.scss';

import { Colors } from '../../types/colors';

export type LinkProps = {
  href?: string;
  isDownloadLink?: boolean;
  shouldOpenInNewTab?: boolean;
  title: string;
};

type TeaserItemProps = PropsWithChildren & {
  backgroundColor?: Colors;
  className?: string;
  link?: LinkProps;
  tracking?: TrackingData;
};

export const TeaserItemLink: FC<TeaserItemProps> = ({
  backgroundColor = 'White',
  children,
  className,
  link,
  tracking,
}) => {
  const { trackCustomEvent } = useTracker();

  const trackEvent = (trackingData?: TrackingData) => {
    trackCustomEvent(trackingData);

    if (link?.href?.includes('onelink')) {
      trackCustomEvent(
        {
          eventName: 'app_link',
          customProps: {
            link_type: 'Onelink',
          },
        },
        undefined,
        false,
      );
    }
  };

  return (
    <Link
      aria-label={link?.title}
      className={classNames('teaser-item', styles.teaser, styles[`teaser-bg-${backgroundColor}`], className)}
      data-testid="teaser-item"
      download={link?.isDownloadLink}
      href={link?.href ?? '#'}
      onClick={() => {
        trackEvent(tracking);
      }}
      target={link?.shouldOpenInNewTab ? '_blank' : undefined}
    >
      {children}
    </Link>
  );
};
