import classNames from 'classnames';
import { FC } from 'react';

import { ArrowUpIconS } from '@lichtblick/icons/svg/arrow-up/arrow-up-s';

import styles from './back-to-top-button.module.scss';

export const BackToTopButton: FC<{ visible: boolean }> = ({ visible }) => (
  <button
    className={classNames(styles.button, visible && styles.visible)}
    onClick={() => {
      scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }}
    tabIndex={-1}
  >
    <ArrowUpIconS />
  </button>
);
