'use client';
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState } from 'react';

export const ProgressContext = createContext<[number, Dispatch<SetStateAction<number>>] | null>(null);

export const ProgressContextWrapper: FC<PropsWithChildren & { initialValue: number }> = ({
  children,
  initialValue,
}) => {
  const state = useState(Number(initialValue));

  return <ProgressContext.Provider value={state}>{children}</ProgressContext.Provider>;
};
