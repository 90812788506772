'use client';
import classNames from 'classnames';
import { FC, HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from 'react';

import Link from '@lichtblick/link';

import styles from './text-cta.module.scss';

export type TextCTAVariant = 'default' | 'plain' | 'thin';

type TextCTABaseBaseProps = {
  className?: string;
  'data-dd-action-name'?: string;
  'data-testid'?: string;
  inlineLink?: boolean;
  slim?: boolean;
  tabIndex?: number;
  variant?: TextCTAVariant;
};

type TextCTAWithChildrenProps = TextCTABaseBaseProps & { ariaLabel?: string; children: ReactNode; icon?: JSX.Element };
type TextCTAWithoutChildrenProps = TextCTABaseBaseProps & { ariaLabel: string; icon: JSX.Element };

type TextCTABaseProps = TextCTAWithChildrenProps | TextCTAWithoutChildrenProps;

export type TextCTAButtonProps = TextCTABaseProps & {
  as: 'button';
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: React.ButtonHTMLAttributes<undefined>['type'];
};

export type TextCTAAnchorProps = TextCTABaseProps & {
  as: 'a';
  href: string;
  isDownload?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: HTMLAttributeAnchorTarget;
};

export type TextCTAProps = TextCTAButtonProps | TextCTAAnchorProps;

export const TextCTA: FC<TextCTAProps> = (props) => {
  const {
    ariaLabel,
    as,
    'data-dd-action-name': ddAction,
    'data-testid': testId,
    icon,
    inlineLink = false,
    slim,
    tabIndex,
    variant = 'default',
  } = props;
  const children = 'children' in props ? props.children : undefined;

  const className = classNames(
    'text-cta',
    styles.cta,
    variant !== 'default' && styles[variant],
    slim && styles.slim,
    inlineLink && styles.inline,
    props.className,
  );

  if (as === 'a') {
    const { href, isDownload = false, onClick, target } = props;

    return (
      <Link
        aria-label={ariaLabel}
        className={className}
        data-dd-action-name={ddAction}
        data-testid={testId}
        download={isDownload}
        href={href}
        onClick={onClick}
        tabIndex={tabIndex}
        target={target}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        {children && <span className={styles.text}>{children}</span>}
      </Link>
    );
  }

  const { isDisabled, isLoading, onClick, type } = props;

  return (
    <button
      aria-label={ariaLabel}
      className={className}
      data-dd-action-name={ddAction}
      data-testid={testId}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {children && <span className={styles.text}>{children}</span>}
    </button>
  );
};
