'use client';

import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import styles from './o-stage-full.module.scss';

import { useOptimizelyFeatureFlag } from '../../hooks/use-optimizely-decision';
import { OStageFullType } from '../../types/storyblok';
import { GridContainer } from '../shared/GridContainer/GridContainer';

type BadgeProps = PropsWithChildren & Pick<OStageFullType, 'badgeColor' | 'badgeFeatureFlag'>;

export const Badge: FC<BadgeProps> = ({ badgeColor = 'orange', badgeFeatureFlag, children }) => {
  const bonusBadgeFlag = useOptimizelyFeatureFlag(badgeFeatureFlag);
  const displayBonusBadge = badgeFeatureFlag ? bonusBadgeFlag : true;

  if (!displayBonusBadge || !children) {
    return null;
  }

  return (
    <GridContainer className={styles['grid-container']}>
      <div className={classNames(styles.badge, styles[`badge-bg-${badgeColor}`])}>{children}</div>
    </GridContainer>
  );
};
