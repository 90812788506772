'use client';

import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

import { AccordionContent, AccordionHeader, AccordionItem } from '@lichtblick/photon/organisms/accordion/accordion';
import { Colors } from '@lichtblick/photon/types/colors';

import { CalculatorLayoutTrigger } from './calculator-layout-trigger';
import styles from './calculator-layout.module.scss';

export type CalculatorLayout = PropsWithChildren & {
  chip?: string;
  headerColor: Colors;
  headline?: string;
  icon: ReactNode;
  id: string;
  isActionTriggerDisabled?: boolean;
};

export const CalculatorLayout: React.FC<CalculatorLayout> = ({
  children,
  chip,
  headerColor,
  headline,
  icon,
  id,
  isActionTriggerDisabled,
}) => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return (
    <AccordionItem className={classNames(styles['accordion-item'])} value={id}>
      <AccordionHeader asChild data-testid="accordion-header">
        <CalculatorLayoutTrigger
          actionTriggerDisabled={isActionTriggerDisabled ?? false}
          chip={chip}
          color={headerColor}
          headline={headline}
          icon={icon}
        />
      </AccordionHeader>
      <AccordionContent isAnimationDisabled={firstRender}>{children}</AccordionContent>
    </AccordionItem>
  );
};
