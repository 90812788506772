import classNames from 'classnames';
import { FC } from 'react';

import styles from './o-teaser-grid.module.scss';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { OTeaserGridType } from '../../types/storyblok';
import { MTeaserItem } from '../MTeaserItem/MTeaserItem';
import { MTextIntro } from '../MTextIntro/MTextIntro';
import { type UnmappedMTeaserItemProps } from '../OTeaserGridArticles/OTeaserGridArticles';

export type OTeaserGridProps = Omit<OTeaserGridType, 'items'> & {
  items: UnmappedMTeaserItemProps[];
  masonry?: boolean;
};

const elementMappers: Partial<ElementMappers> = {
  a: ({ children }) => <>{children}</>,
  p: ({ children }) => <>{children}</>,
};

export const OTeaserGrid: FC<OTeaserGridProps> = ({
  accent,
  anchorId,
  columns,
  items,
  masonry = false,
  textIntro,
  theme,
}) => {
  const columnCount = Number(columns) === 3 ? 3 : 2;

  if (items.length === 0) {
    return null;
  }

  return (
    <section
      className={classNames(
        styles.section,
        styles[`section-bg-${theme}`],
        styles[`section-cols-${columnCount}`],
        masonry && styles['section-masonry'],
      )}
      data-testid="teaser-grid"
      id={anchorId ?? undefined}
    >
      {textIntro?.length === 1 && !masonry && (
        <MTextIntro
          {...textIntro[0]}
          accentColor={accent}
          buttonVariant="link"
          centeredDesktop
          centeredMobile
          className={styles['text-intro']}
        />
      )}
      {items.map((item) => (
        <MTeaserItem
          {...item}
          accentColor={accent ?? 'black'}
          isTextOutside={false}
          key={item._uid}
          text={
            typeof item.text === 'object' ? (
              <RichText elementMappers={elementMappers} richText={item.text} />
            ) : (
              item.text
            )
          }
        />
      ))}
    </section>
  );
};
