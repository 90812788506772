import { LazySvgMap } from '../components/lazy-svg-map';

export type IconName = keyof typeof LazySvgMap;

export const capitalize = (word: string) => `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;

export const toIconName = (name: string) => {
  if (name in LazySvgMap) {
    return name as IconName;
  }

  const words = name.split(/\W+/);
  const sizeMarker = words[words.length - 1]?.match(/^(m|x*[sl])$/) && words.pop(); // Array.prototype.at is not compatible with safari < 15.4

  // insert "icon" at end (but before size marker)
  words.push('icon');
  words.push(sizeMarker ?? '');

  // pascalize
  return words.map(capitalize).join('') as IconName;
};
