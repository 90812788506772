import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { TrackingData } from '@lichtblick/tracker';

import { LinkProps, TeaserItemLink } from './teaser-item-link';
import styles from './teaser-item.module.scss';

import { Chip } from '../../atoms/chip/chip';
import { Headline } from '../../atoms/headline/headline';
import { Text } from '../../atoms/text/text';
import { Colors } from '../../types/colors';

export type TeaserItemProps = PropsWithChildren<{
  backgroundColor?: Colors;
  chip?: string;
  className?: string;
  headline?: string;
  link?: LinkProps;
  media?: ReactNode;
  text?: string | ReactNode;
  textOutside?: boolean;
  tracking?: TrackingData;
}>;

export const TeaserItem: FC<TeaserItemProps> = ({
  backgroundColor = 'White',
  chip,
  className,
  headline,
  link,
  media,
  text,
  textOutside = false,
  tracking,
}) => {
  const content = (
    <div className={classNames(styles.content, textOutside && styles['content-text-outside'])}>
      {chip && <Chip className={styles.chip}>{chip}</Chip>}
      {headline && (
        <Headline className={styles.headline} size="s">
          {headline}
        </Headline>
      )}
      {text && <Text className={styles.text}>{text}</Text>}
      {!textOutside && link?.title && (
        <div className={styles['text-cta']}>
          <Text bold className={styles['text-cta-text']} size="l">
            {link?.title}
          </Text>
        </div>
      )}
    </div>
  );

  return (
    <TeaserItemLink backgroundColor={backgroundColor} className={className} link={link} tracking={tracking}>
      <div
        className={classNames(
          styles.container,
          !textOutside && backgroundColor && styles[`container-bg-${backgroundColor}`],
        )}
      >
        {media && <div className={classNames(styles.media)}>{media}</div>}
        {!textOutside && content}
      </div>
      {textOutside && content}
    </TeaserItemLink>
  );
};
