'use client';

import { FormEvent, useRef, useState } from 'react';

import { HouseCarIconM } from '@lichtblick/icons/svg/house-car/house-car-m';
import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { useTracker } from '@lichtblick/tracker';

import { CalculatorLayout } from './calculator-layout';
import styles from './calculator-layout.module.scss';
import { ChargingUsageInput } from './ChargingUsageInput';
import { CityInput } from './CityInput';
import { carUsage, ELECTRICITY_USAGE, mileageLimit, PEOPLE_LIMIT, usageLimitElectricity } from './constants';
import { ElectrictyUsageInput } from './ElectricityUsageInput';
import { CalculatorTranslations } from './translations';
import { UsageModal } from './UsageModal';
import { useVpCodes } from './useVpCodes';

import { buildUrl } from '../../helpers/links';
import { HomeChargingCalculatorType } from '../../types';

type FormValues = {
  carUsage?: string;
  city?: string;
  electricityUsage?: string;
  mileage?: number;
  peopleCount?: number;
  postcode?: string;
};

type HomeChargingCalculatorProps = HomeChargingCalculatorType & Pick<CalculatorLayout, 'isActionTriggerDisabled'>;

export const HomeChargingCalculator: React.FC<HomeChargingCalculatorProps> = ({
  _uid,
  checkoutUrl,
  chip,
  directSalesPartnerCode,
  headline,
  isActionTriggerDisabled,
  powerCloudCampaignId,
  useDirectSalesPartnerCodeSitewide,
}) => {
  const { computeVpCodes } = useVpCodes();
  const { trackCustomEvent } = useTracker();
  const peopleUsageRef = useRef<HTMLInputElement>(null);
  const distanceUsageRef = useRef<HTMLInputElement>(null);
  const [isElectricityUsageModalOpen, setIsElectricityUsageModalOpen] = useState(false);

  const [values, setValues] = useState<FormValues>({
    carUsage: String(carUsage.default),
    peopleCount: PEOPLE_LIMIT.min,
    mileage: mileageLimit.default,
    electricityUsage: String(ELECTRICITY_USAGE[0]),
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (peopleUsageRef.current && Number(peopleUsageRef.current.value) > usageLimitElectricity.max) {
      setIsElectricityUsageModalOpen(true);

      return;
    }

    if (distanceUsageRef.current && Number(distanceUsageRef.current.value) > usageLimitElectricity.max) {
      setIsElectricityUsageModalOpen(true);

      return;
    }

    trackCustomEvent({
      eventName: 'tariff_calculator_show_tariffs',
      customProps: {
        product: 'ZuHause Plus',
        people_counter: values?.peopleCount,
        kilometers_traveled: `${values?.mileage} km/Jahr`,
        consumption_calculator: `${values?.electricityUsage} kWh/Jahr;${values?.carUsage} kWh/Jahr`,
        zip_code: values?.postcode,
      },
    });

    const vpCodes = computeVpCodes(directSalesPartnerCode, useDirectSalesPartnerCodeSitewide);

    const params = new URLSearchParams({
      ...(values?.city && { ort: values.city }),
      ...(values?.postcode && { plz: values.postcode }),
      ...(values?.electricityUsage && { zuhause: values.electricityUsage }),
      ...(values?.carUsage && { auto: values.carUsage }),
      ...(powerCloudCampaignId && { cam: powerCloudCampaignId }),
      ...(vpCodes?.vp && { vp: vpCodes.vp }),
      ...(vpCodes?.uvp && { uvp: vpCodes.uvp }),
    });

    location.assign(`${buildUrl(checkoutUrl) ?? '/zuhause-laden-checkout/'}?${params.toString()}`);
  };

  return (
    <CalculatorLayout
      chip={chip}
      headerColor="LightMoss"
      headline={headline}
      icon={<HouseCarIconM />}
      id={_uid}
      isActionTriggerDisabled={isActionTriggerDisabled}
    >
      <form className={styles['calculator-content']} method="get" onSubmit={handleSubmit}>
        <div className={styles['calculator-input-row']}>
          <ElectrictyUsageInput
            onChange={(value, peopleCount) =>
              setValues((prev) => ({ ...prev, electricityUsage: value, peopleCount: peopleCount }))
            }
            usageRef={peopleUsageRef}
          >
            <UsageModal
              isOpen={isElectricityUsageModalOpen}
              route="/gewerbestrom"
              setIsOpen={setIsElectricityUsageModalOpen}
            />
          </ElectrictyUsageInput>
          <ChargingUsageInput
            onChange={(value, mileage) => setValues((prev) => ({ ...prev, carUsage: value, mileage: mileage }))}
            usageRef={distanceUsageRef}
          />
        </div>
        <CityInput
          onChange={(city, postcode) => {
            setValues((prev) => ({ ...prev, city: city, postcode: postcode }));
          }}
        />
        <BoxCTA as="button" data-testid="calc-submit" type="submit" wide>
          {CalculatorTranslations.submitLabel}
        </BoxCTA>
      </form>
    </CalculatorLayout>
  );
};
