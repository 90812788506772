'use client';

import { useActionState, useEffect, useRef, useState } from 'react';

import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { Li, Ul } from '@lichtblick/photon/atoms/list/list';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { CheckboxInput } from '@lichtblick/photon/molecules/checkbox/checkbox-input';

import { submitForm } from './actions';
import styles from './kwk-form.module.scss';
import { Textfield } from './Textfield';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { KwkFormType } from '../../types/storyblok';
import { OInfoteaser } from '../OInfoteaser/OInfoteaser';

const richTextMapperOverride: Partial<ElementMappers> = {
  p: ({ children }) => (
    <Text renderAs="p" size="m" spacing>
      {children}
    </Text>
  ),
  ul: ({ children }) => <Ul className={styles.ul}>{children}</Ul>,
  li: ({ children }) => <Li className={styles.li}>{children}</Li>,
};

export const KWKForm: React.FC<KwkFormType> = ({
  aknowledgement,
  infoTeaser,
  intro,
  optin,
  privacyNote,
  successTeaser,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [missingOptin, setMissingOptin] = useState(false);

  const [state, dispatch, pending] = useActionState(submitForm, {
    submitted: false,
    inputs: {
      contractId: '',
      counterNumber: '',
      emailAddress: '',
      optIn: false,
    },
  });

  useEffect(() => {
    if (!state.submitted) {
      return;
    }

    containerRef.current?.scrollIntoView({ block: 'center' });
  }, [state.submitted, containerRef]);

  return state.submitted ? (
    <div ref={containerRef}>
      <OInfoteaser {...successTeaser[0]} />
    </div>
  ) : (
    <>
      {infoTeaser?.length === 1 && <OInfoteaser {...infoTeaser[0]} />}

      <form action={dispatch} className={styles.form}>
        <RichText elementMappers={richTextMapperOverride} richText={intro} />

        <Textfield
          defaultValue={state.inputs.contractId}
          forcedError={state.errors?.includes('CustomerContractNotFoundError')}
          label="Vertragsnummer"
          maxLength={8}
          name="contractId"
          pattern="\d{7,8}"
          required
        />
        <Textfield
          defaultValue={state.inputs.counterNumber}
          forcedError={state.errors?.includes('CounterNumberNotFoundError')}
          label="Zählernummer"
          maxLength={40}
          name="meterId"
          required
          type="text"
        />
        <Textfield defaultValue={state.inputs.emailAddress} label="E-Mail-Adresse" name="email" required type="email" />

        <div>
          <RichText elementMappers={richTextMapperOverride} richText={aknowledgement} />
        </div>

        <CheckboxInput
          defaultChecked={state.inputs.optIn}
          hasError={missingOptin}
          label={optin}
          name="optIn"
          onChange={(e) => {
            setMissingOptin(!e.currentTarget.validity.valid);
          }}
          onInvalid={(e) => {
            e.preventDefault();
            setMissingOptin(!e.currentTarget.validity.valid);
          }}
          required
          statusLabel={missingOptin ? 'Hier fehlt noch eine Angabe' : undefined}
        />

        <input name="optInText" type="hidden" value={optin} />

        <BoxCTA as="button" loading={pending} type="submit">
          Absenden
        </BoxCTA>
        <RichText richText={privacyNote} />
      </form>
    </>
  );
};
