'use client';

import Script from 'next/script';
import { FC, useState } from 'react';

import { Box } from '@lichtblick/ui-components';
import { Loader } from '@lichtblick/ui-components/atoms/Loader/Loader';

import styles from './equota.module.scss';

import { AppConfigType } from '../../../types/storyblok';
import { GridColumn, GridContainer, GridRow } from '../../shared';

export const Equota: FC<AppConfigType> = ({ objects }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const parsedObjects = typeof objects === 'object' ? objects : objects && JSON.parse(objects);

  return (
    <>
      <Script
        onLoad={() => {
          setIsLoading(false);
          document.dispatchEvent(new Event('initSnowflake'));
        }}
        src="https://static.lichtblick.de/snowflakes/nextjs/equota/main.62f5877c4d92c9ae0f92f1c4914811e2.js"
      />
      <link
        href="https://static.lichtblick.de/react-components/hugo-apps.css"
        key="hugo-apps-styles"
        rel="stylesheet"
      />
      <div className="snowflake-equota">
        <Box className="o-media o-media--layout-medium theme--white theme--light">
          <GridContainer>
            <GridRow>
              <GridColumn $width={12}>
                <figure className="m-media">
                  <div
                    className="m-iframe m-iframe--responsive"
                    style={
                      {
                        '--aspect-ratio-desktop': '1600/900',
                        '--aspect-ratio-mobile': '400/600',
                      } as React.CSSProperties
                    }
                  >
                    {isLoading && <Loader className={styles['loader']} />}
                    <iframe
                      allow="geolocation"
                      className="m-iframe__iframe"
                      data-form-id={parsedObjects?.form_id}
                      height="100%"
                      id="equotaCustomerIframe"
                      loading="lazy"
                      src=""
                      title={parsedObjects?.title}
                      width="100%"
                    />
                  </div>
                </figure>
              </GridColumn>
            </GridRow>
          </GridContainer>
        </Box>
      </div>
    </>
  );
};

export default Equota;
