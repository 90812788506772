'use client';

import { FC, useEffect } from 'react';

import { GOOGLE_ANALYTICS_NAME } from '@lichtblick/consent/ConsentManager/ConsentManager.types';
import { useConsent } from '@lichtblick/consent/hooks/useConsent';

import { useTemplateTracker } from '../../hooks/useTemplateTracker';
import { TNotificationType } from '../../types/storyblok';

const EEC_PURCHASE_SESSION_STORAGE_KEYS = ['EECpurchase', 'GA4EECpurchase', 'GA4EMobilityContactForm'];

export const TemplateTracker: FC<{
  contentGroup?: string;
  contentGroup2?: string;
  contentGroup3?: string;
  metaTitle?: string;
  trackingPageArea?: string;
  trackingPageType: TNotificationType['trackingPageType'];
}> = ({ contentGroup, contentGroup2, contentGroup3, metaTitle, trackingPageArea, trackingPageType }) => {
  const consent = useConsent(GOOGLE_ANALYTICS_NAME);
  const isTrackingEnabled = consent;

  useEffect(() => {
    const interval = setInterval(() => {
      const firedEvents = window.dataLayer.map((event) => event.event);

      if (!['consent_status', 'virtPath', 'Page Meta'].every((event) => firedEvents.includes(event))) {
        return;
      }

      clearInterval(interval);

      // due to timing issues the old checkouts store certain events in session storage
      // and rely on the order confirmation page to send them to the data layer
      EEC_PURCHASE_SESSION_STORAGE_KEYS.forEach((storageKey) => {
        const eventJson = sessionStorage.getItem(storageKey);

        if (isTrackingEnabled || !eventJson) {
          return;
        }

        try {
          const trackingEvent = JSON.parse(eventJson);

          if (trackingEvent && window.dataLayer) {
            window.dataLayer.push(trackingEvent);
            sessionStorage.removeItem(storageKey);
          }
        } catch (e) {
          //
        }
      });
    }, 100);
  }, [isTrackingEnabled]);

  useTemplateTracker({
    contentGroup,
    contentGroup2,
    contentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: trackingPageType?.substring(2) ?? '',
    pageTitle: metaTitle ?? '',
  });

  return null;
};
