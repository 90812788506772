'use client';

import { FC } from 'react';

import { Header as HeaderComponent } from '@lichtblick/ui-components';

import { useNavigationItems } from '../../hooks/useNavigationItems';
import { SettingsType } from '../../types';

export type HeaderSettings = Pick<SettingsType, 'metaNavigation' | 'primaryNavigation'>;

export const Header: FC<HeaderSettings> = ({ metaNavigation, primaryNavigation }) => {
  const memoedPrimaryNavigation = useNavigationItems(primaryNavigation ?? []);
  const memoedMetaNavigation = useNavigationItems(metaNavigation ?? []);

  return <HeaderComponent metaNavigation={memoedMetaNavigation} primaryNavigation={memoedPrimaryNavigation} />;
};
