'use client';

import { FC, useCallback } from 'react';

import { useTracker } from '@lichtblick/tracker';
import { Text, TextCTA } from '@lichtblick/ui-components';

import { ColumnItemContainer, StyledHeadline } from './MColumnItem.styles';

import { buildUrl } from '../../helpers';
import { assertExhaustive } from '../../helpers/assertExhaustive';
import { MColumnItemType } from '../../types/storyblok';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';
import { MAnimatedIllustration } from '../MAnimatedIllustration/MAnimatedIllustration';
import { SvgIcon } from '../SvgIcon/SvgIcon';

export type ColumnItemProps = MColumnItemType & {
  columnAlignment?: string | null;
  columnWidth?: string | null;
};

const MediaContent: FC<{ content: ColumnItemProps['media'] }> = ({ content: contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} />;
    case 'a-video':
      return <AVideo {...content} />;
    case 'm-animated-illustration':
      return <MAnimatedIllustration {...content} />;
    case 'svg-icon':
      return <SvgIcon {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const MColumnItem: FC<ColumnItemProps> = ({
  columnAlignment,
  columnWidth,
  headline,
  link,
  linkLabel,
  media,
  text,
  textFirst = false,
}) => {
  const { trackCustomEvent } = useTracker();

  const linkClickHandler = useCallback(() => {
    trackCustomEvent({
      customProps: { navigation_area: 'Body', navigation_element: linkLabel },
      eventName: 'navigation',
    });
  }, [linkLabel, trackCustomEvent]);

  const href = link && buildUrl(link);

  return (
    <ColumnItemContainer $alignment={columnAlignment ?? ''} $width={columnWidth ?? ''} data-testid="column-item">
      {!textFirst && <MediaContent content={media} />}
      {headline && (
        <StyledHeadline renderAs="h3" size="S">
          {headline}
        </StyledHeadline>
      )}
      {text && <Text>{text}</Text>}
      {Boolean(textFirst) && <MediaContent content={media} />}
      {Boolean(href) && Boolean(linkLabel) && (
        <TextCTA as="a" href={href} onClick={linkClickHandler}>
          {linkLabel}
        </TextCTA>
      )}
    </ColumnItemContainer>
  );
};
