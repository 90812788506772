'use client';

import { FC } from 'react';

import { IconName } from '@lichtblick/icons';

import { PlaceholderItem, StyledIcon, StyledItem, Title, Value, ValuePrimary, Wrapper } from './ADataItem.styles';

import { ColorKey, colorMap } from '../../utils';

export type DataItemProps = {
  className?: string;
  color?: ColorKey;
  hasDottedLine?: boolean;
  hasTopLine?: boolean;
  icon?: IconName;
  iconColor?: ColorKey;
  isBold?: boolean;
  isInBoldList?: boolean;
  isLoading?: boolean;
  textColor?: ColorKey;
  title: string;
  topLineColor?: ColorKey;
  value: string;
};

export const ADataItem: FC<DataItemProps> = ({
  className,
  color,
  hasDottedLine,
  hasTopLine,
  icon,
  iconColor,
  isBold,
  isInBoldList,
  isLoading,
  textColor,
  title,
  topLineColor,
  value,
}) => {
  if (isLoading) {
    return <PlaceholderItem className={className} />;
  }

  return (
    <StyledItem $hasTopLine={hasTopLine} $topLineColor={topLineColor && colorMap[topLineColor]} className={className}>
      {icon && <StyledIcon $color={iconColor} name={icon} />}
      <Wrapper
        $hasDottedLine={hasDottedLine ?? false}
        $textColor={color ? colorMap[color] : textColor && colorMap[textColor]}
      >
        <Title bold={isInBoldList || isBold} size="xs">
          {title}
        </Title>
        {hasTopLine ? (
          <ValuePrimary bold={isBold} size="xs">
            {value}
          </ValuePrimary>
        ) : (
          <Value bold={isBold} size="xs">
            {value}
          </Value>
        )}
      </Wrapper>
    </StyledItem>
  );
};
