import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './footnote.module.scss';

import { Text } from '../../atoms/text/text';
import { Colors } from '../../types/colors';

export type FootnoteProps = {
  children: React.ReactNode;
  className?: string;
  color?: Colors;
  index: string;
};

export const Footnote: FC<FootnoteProps> = ({ children, className, color = 'DarkGray', index }) => (
  <Text
    className={classNames('footnote', styles.footnote, styles[`color-${color}`], className)}
    renderAs={'div'}
    size="xs"
  >
    <sup>{index}</sup>
    {children}
  </Text>
);
