'use client';

import { ChangeEvent, FC } from 'react';

import { Toggle } from '@lichtblick/ui-components';

import { AToggleType } from '../../types/storyblok';

const noop = () => undefined;

export type AToggleProps = AToggleType & {
  isChecked?: boolean;
  isDisabled?: boolean;
  name?: string;
  onClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

export const AToggle: FC<AToggleProps> = ({ ariaLabel, isChecked, isDisabled, name, onClick, text, value }) => (
  <Toggle
    ariaLabel={ariaLabel ?? undefined}
    isChecked={isChecked ?? false}
    isDisabled={isDisabled}
    label={text ?? ''}
    name={name ?? ''}
    onClick={onClick ?? noop}
    value={value}
  />
);
