import classNames from 'classnames';
import { FC } from 'react';

import styles from './m-table.module.scss';

import { MTableType } from '../../types/storyblok';
import { AText } from '../AText/AText';

export const MTable: FC<MTableType> = ({ ariaLabel, tableData }) => {
  if (!tableData) {
    return null;
  }

  return (
    <div className={classNames(styles.container)} data-testid="mtable">
      <table aria-label={ariaLabel ?? undefined} className={styles.table}>
        {Boolean(tableData.thead?.some(({ value }) => Boolean(value))) && (
          <thead>
            <tr>
              {tableData.thead?.map(({ value }) => (
                <th key={value}>
                  <AText bold size="l">
                    {value}
                  </AText>
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {tableData.tbody?.map((row) => (
            <tr key={row._uid}>
              {row.body.map(({ _uid, value }) => (
                <td key={_uid}>
                  <AText size="l">{value}</AText>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
