'use client';

import { FC, useEffect, useRef, useState, useMemo } from 'react';

import { Colors, Spacing } from '@lichtblick/theme';
import { useTracker } from '@lichtblick/tracker';
import { Box, Tabs, TabsType } from '@lichtblick/ui-components';

import {
  ComparisonCard,
  ComparisonCards,
  ComparisonFooter,
  GridRowExtend,
  StyledText,
} from './OTariffComparison.styles';

import { MTariffCardType, OTariffComparisonType } from '../../types/storyblok';
import { AButton } from '../AButton';
import { MTariffCard } from '../MTariffCard';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

const CARDS_INTERSECTION_THRESHOLD = 0.3;

const buildTrackingObject = (card: MTariffCardType, index: number) => ({
  item_id: new URLSearchParams(card.button?.[0]?.linkUrl?.split('?')[1]).get('productCode') ?? '',
  item_name: card.headline,
  item_category: 'B2C E-Mob',
  index: index + 1,
  item_list_name: 'B2C E-Mob',
  price: 0,
});

const OTariffComparison: FC<OTariffComparisonType> = ({ accent, anchorId, button, cards, footnote, textIntro }) => {
  const renderTabContent = (items: MTariffCardType[]) => {
    return (
      <ComparisonCards>
        {items?.map((item, index) => {
          return (
            <ComparisonCard key={item._uid}>
              <MTariffCard {...item} onClick={() => trackClickEvent(item, index)} />
            </ComparisonCard>
          );
        })}
      </ComparisonCards>
    );
  };

  const tabs = useMemo<TabsType | undefined>(() => {
    const cardsWithContract = cards?.filter((item) => {
      return item.withEnergyContract === true;
    });
    const cardsWithoutContract = cards?.filter((item) => {
      return item.withEnergyContract === false;
    });

    if (
      cardsWithContract &&
      cardsWithoutContract &&
      cardsWithContract?.length > 0 &&
      cardsWithoutContract?.length > 0
    ) {
      const tabsWithContract = renderTabContent(cardsWithContract);
      const tabsWithoutContract = renderTabContent(cardsWithoutContract);

      return [
        { headline: 'Neukund*in', contentSlot: tabsWithoutContract },
        { headline: 'Bestandskund*in', contentSlot: tabsWithContract },
      ];
    }
  }, [cards]);

  const { isVirtPathTracked, trackCustomEvent, trackEcommerceEvent } = useTracker();
  const ref = useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.intersectionRatio >= CARDS_INTERSECTION_THRESHOLD),
      {
        threshold: CARDS_INTERSECTION_THRESHOLD,
      },
    );

    const comparisonCards = ref.current;

    if (comparisonCards) {
      intersectionObserver.observe(comparisonCards);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isIntersecting && isVirtPathTracked) {
      trackEcommerceEvent({
        event: 'Ecommerce - Item List Views',
        event_name: 'view_item_list',
        view_item_list: {
          currency: 'EUR',
          bundle_intention: false,
          items: cards?.map(buildTrackingObject) ?? [],
        },
      });
    }
  }, [cards, isIntersecting, isVirtPathTracked, trackEcommerceEvent]);

  const trackClickEvent = (card: MTariffCardType, index: number) => {
    if (card?.button?.[0]?.trackingOption?.[0]?.eventName) {
      const trackingOption = card.button[0].trackingOption[0];

      trackCustomEvent({
        eventName: trackingOption.eventName,
        customProps: trackingOption.customProps && JSON.parse(trackingOption.customProps),
      });
    }

    trackEcommerceEvent({
      event: 'Ecommerce - Add to cart',
      event_name: 'add_to_cart',
      add_to_cart: {
        bundle_intention: false,
        currency: 'EUR',
        items: [buildTrackingObject(card, index)],
      },
    });
  };

  return (
    <Box backgroundColor={Colors.FadedGray} data-testid="tariff-comparison" pyd={Spacing.Xl} pym={Spacing.M}>
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainer>
        {textIntro?.length === 1 && (
          <GridRowExtend>
            <GridColumn $push={2} $width={8}>
              <MTextIntro {...textIntro[0]} accentColor={accent} centeredDesktop centeredMobile />
            </GridColumn>
          </GridRowExtend>
        )}
        <GridRow>
          <GridColumn $push={2} $width={8}>
            {tabs ? (
              <Tabs gap={Spacing.M} mobileNavAlignment="column" tabs={tabs} />
            ) : (
              <ComparisonCards ref={ref}>
                {cards &&
                  cards.map((item, index) => (
                    <ComparisonCard key={item._uid}>
                      <MTariffCard {...item} onClick={() => trackClickEvent(item, index)} />
                    </ComparisonCard>
                  ))}
              </ComparisonCards>
            )}
          </GridColumn>
        </GridRow>

        {(button || footnote) && (
          <GridRow>
            <ComparisonFooter>
              {button?.length === 1 && <AButton {...button[0]} />}
              {footnote && (
                <StyledText renderAs="small" size="xs">
                  {footnote}
                </StyledText>
              )}
            </ComparisonFooter>
          </GridRow>
        )}
      </GridContainer>
    </Box>
  );
};

export default OTariffComparison;
