import { toIconName } from '@lichtblick/icons';
import { TrackingData } from '@lichtblick/tracker';
import { NavigationItemProps } from '@lichtblick/ui-components';

import { ANavigationItemType } from '../types';

import { buildUrl } from '.';

export const getPropsFromItems = (items: ANavigationItemType[]): NavigationItemProps[] =>
  items.map(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ({ icon, linkEntry, linkUrl, list, openInNewTab, text, trackingOption }) => {
      const href = (linkUrl || (linkEntry && buildUrl(linkEntry))) ?? '#';
      let tracking: TrackingData | undefined;

      try {
        tracking =
          trackingOption?.length === 1
            ? {
                eventName: trackingOption[0].eventName,
                customProps: trackingOption[0].customProps ? JSON.parse(trackingOption[0].customProps) : undefined,
              }
            : undefined;
      } catch (e) {
        //
      }

      return {
        text: text ?? '',
        icon: icon ? toIconName(icon) : undefined,
        href,
        shouldOpenInNewTab: openInNewTab,
        tracking,
        items: list && getPropsFromItems(list),
      };
    },
  );
