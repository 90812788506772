'use client';

import { create } from '@lottiefiles/lottie-interactivity';
import { Player } from '@lottiefiles/react-lottie-player';
import { AnimationItem } from 'lottie-web';
import { FC, useEffect, useState } from 'react';

import { MAnimatedIllustrationType } from '../../types/storyblok';

export const LottiePlayer: FC<
  Pick<MAnimatedIllustrationType, 'height' | 'lottieCustomActions' | 'lottieMode' | 'width'> & {
    className?: string;
    lottieAnimationData: NonNullable<MAnimatedIllustrationType['lottieAnimationData']>;
  }
> = ({ className, height, lottieAnimationData, lottieCustomActions, lottieMode, width }) => {
  const [lottieInstance, setLottieInstance] = useState<AnimationItem>();

  useEffect(() => {
    if (lottieInstance?.isLoaded && lottieMode !== 'autoplay') {
      const defaultActions = [{ type: 'seek', visibility: [0.1, 0.9], frames: [0, lottieInstance?.totalFrames] }];

      let parsedCustomActions;

      try {
        if (lottieCustomActions) {
          parsedCustomActions = JSON.parse(lottieCustomActions);
        }
      } catch (e) {
        //
      }

      create({
        mode: 'scroll',
        player: lottieInstance,
        actions: parsedCustomActions ?? defaultActions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lottieInstance]);

  return (
    <Player
      className={className}
      lottieRef={(instance) => {
        setLottieInstance(instance);
      }}
      {...(lottieMode === 'autoplay' && { autoplay: true, loop: true })}
      src={lottieAnimationData.filename}
      style={{ aspectRatio: `${width ?? '1'} / ${height ?? '1'}` }}
    />
  );
};
