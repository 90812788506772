'use client';

import { FC, ReactNode } from 'react';

import { AKLAMIO_NAME } from '@lichtblick/consent/ConsentManager/ConsentManager.types';
import { useConsent } from '@lichtblick/consent/hooks/useConsent';

type OptionSwitcherProps = { disabled: ReactNode; enabled: ReactNode };

export const OptinSwitcher: FC<OptionSwitcherProps> = ({ disabled, enabled }) => {
  const consentState = useConsent(AKLAMIO_NAME);

  return consentState ? enabled : disabled;
};
