'use client';
import classNames from 'classnames';
import { FC, MouseEventHandler } from 'react';

import { NavigationItemProps } from '@lichtblick/photon/types/navigationItemProps';

import { FooterSettings } from './Footer';
import styles from './Footer.module.scss';
import { SecondaryNavigationCTA } from './SecondaryNavigationCTA';

import { getPropsFromItems } from '../../helpers/getNavigationProps';

const openUsercentricsSecondLayer: MouseEventHandler<HTMLAnchorElement> = (event) => {
  event.preventDefault();
  event.stopPropagation();
  window.__ucCmp?.showSecondLayer();
};

const attachUsercentricsClickHandler = (item: NavigationItemProps): NavigationItemProps => ({
  ...item,
  ...(item.href === '#uc-central-modal-show' ? { onClick: openUsercentricsSecondLayer } : {}),
  items: item.items?.map(attachUsercentricsClickHandler),
});

export const SecondaryNavigation: FC<{
  footerSecondaryNavigation: FooterSettings['footerSecondaryNavigation'];
}> = ({ footerSecondaryNavigation }) => {
  const secondaryNavigation = getPropsFromItems(footerSecondaryNavigation ?? []).map(attachUsercentricsClickHandler);

  if (!secondaryNavigation) {
    return null;
  }

  return (
    <menu className={classNames(styles.menu, [styles['menu-secondary']])}>
      <div className={styles['secondary-nav']}>
        {secondaryNavigation[0]?.items?.map(({ href, onClick, text, tracking }) => (
          <SecondaryNavigationCTA href={href} key={text} onClick={onClick} text={text} tracking={tracking} />
        ))}
      </div>
    </menu>
  );
};
