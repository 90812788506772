import { ElementType, FC, PropsWithChildren } from 'react';

import { AccentText, AccentTextSizeClass } from '../../atoms/accent-text/accent-text';
import { Text, TextSizeClass } from '../../atoms/text/text';

export type LabelProps = PropsWithChildren<
  {
    bold?: boolean;
    className?: string;
    htmlFor?: string;
    id?: string;
    renderAs?: ElementType;
  } & (
    | {
        isAccentText: true;
        size?: AccentTextSizeClass;
      }
    | {
        isAccentText?: false;
        size?: TextSizeClass;
      }
  )
>;

export const Label: FC<LabelProps> = ({ bold, children, isAccentText, renderAs = 'label', size, ...props }) => {
  return isAccentText ? (
    <AccentText renderAs={renderAs} size={size} {...props}>
      {children}
    </AccentText>
  ) : (
    <Text bold={bold} renderAs={renderAs} size={size} {...props}>
      {children}
    </Text>
  );
};
