import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { NavigationItemProps } from '@lichtblick/ui-components';

import { getPropsFromItems } from '../helpers/getNavigationProps';
import { ANavigationItemType } from '../types/storyblok';

const sanitizePathname = (pathname: string) => pathname.replace(/\/$/, '');

// set "Konto" navigation item to active if the current location is /konto or a subpage of /konto
const isSvB2cActive = (href: string): boolean => {
  const svB2cPath = '/konto';
  const isLocationSvB2c = location.pathname.startsWith(svB2cPath);
  let isItemHrefSvB2c = false;

  // checking both cases if href is a full url or a relative path
  try {
    const url = new URL(href);

    isItemHrefSvB2c = url.pathname.startsWith(svB2cPath);
  } catch (e) {
    isItemHrefSvB2c = href.startsWith(svB2cPath);
  }

  return isLocationSvB2c && isItemHrefSvB2c;
};

const isCurrent = ({ href = '' }: NavigationItemProps): boolean => {
  const sanitizedHref = sanitizePathname(href);
  const pathname = sanitizePathname(location.pathname);

  return (
    href !== '' &&
    (sanitizedHref === pathname || sanitizedHref === `${location.origin}${pathname}` || isSvB2cActive(href))
  );
};

const markActiveItems = (items: NavigationItemProps[]): NavigationItemProps[] =>
  items.map((item) => {
    const items = item.items && markActiveItems(item.items);
    const isActive = isCurrent(item) || items?.some(({ isActive }) => isActive);

    return { ...item, items, isActive };
  });

export const useNavigationItems = (items: ANavigationItemType[]) => {
  const pathname = usePathname();
  const [navigationItemProps, setNavigationItemProps] = useState(getPropsFromItems(items));

  useEffect(() => {
    setNavigationItemProps(markActiveItems(getPropsFromItems(items)));
    // having items in the deps causes infinite excution of the hook after a couple navigations
    // TODO: make state obselete for navigation items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return navigationItemProps;
};
