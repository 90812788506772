'use client';
import { FC, ReactNode, useState } from 'react';

import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';

export type ExpanderProps = { items: ReactNode[]; visibleItems: number };

import styles from './o-index.module.scss';

export const Expander: FC<ExpanderProps> = ({ items, visibleItems }) => {
  const [isExpanderOpen, setIsExpanderOpen] = useState(false);

  return (
    <>
      <ul>{items.slice(0, isExpanderOpen ? items.length : visibleItems).map((item) => item)}</ul>

      <TextCTA as="button" className={styles['expander-button']} onClick={() => setIsExpanderOpen(!isExpanderOpen)}>
        {isExpanderOpen ? 'Weniger anzeigen' : 'Mehr anzeigen'}
      </TextCTA>
    </>
  );
};
