import { Content, Overlay, Portal, Root, Title } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';

import styles from './modal.module.scss';

export type ModalProps = PropsWithChildren<{
  footerContent?: ReactNode;
  isOpen?: boolean;
  onPointerDownOutside?: () => void;
  title: string;
}>;

export const Modal: FC<ModalProps> = ({ children, footerContent, isOpen, onPointerDownOutside, title }) => (
  <Root open={isOpen}>
    <Portal>
      <Overlay className={styles.overlay} />

      <Content className={classNames(styles['modal-surface'])} onPointerDownOutside={onPointerDownOutside}>
        <VisuallyHidden>
          <Title>{title}</Title>
        </VisuallyHidden>
        <div className={styles['content-wrapper']}>
          <div className={styles['modal-content']}>{children}</div>
          <div className={styles['bottom-shadow-curtain']} />
        </div>
        <footer className={styles['modal-footer']}>{footerContent}</footer>
      </Content>
    </Portal>
  </Root>
);
