'use client';

import { usePathname } from 'next/navigation';
import { FC, useEffect } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { initReactComponents } from './reactComponentsInitializer';
import { initTracking } from './reactTrackingInitializer';

export const LegacyReactComponentInitializer: FC = () => {
  const pathname = usePathname();
  const { trackCustomEvent } = useTracker();

  useEffect(() => {
    initReactComponents();

    return initTracking(trackCustomEvent);
  }, [pathname, trackCustomEvent]);

  return null;
};
