import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const hasMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }

    return false;
  };

  const [isMatch, setIsMatch] = useState<boolean>(hasMatches(query));

  const handleChange = () => {
    setIsMatch(hasMatches(query));
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return isMatch;
};
