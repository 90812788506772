import classNames from 'classnames';
import { FC } from 'react';

import { TextProps, Text } from '@lichtblick/photon/atoms/text/text';

import styles from './a-text.module.scss';

export const AText: FC<TextProps> = (props) => {
  return <Text {...props} className={classNames(props.className, styles.text)} />;
};
