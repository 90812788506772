'use client';
import { FC } from 'react';

import { PhoneIconXs } from '@lichtblick/icons/svg/phone/phone-xs';
import { Colors, Spacing } from '@lichtblick/theme';

import { ContactLink, StyledBox, StyledLink, StyledText } from './ContactInfo.styles';

import { GridContainer, GridRow, GridColumn } from '../shared/GridContainer';

export type ContactInfoProps = {
  phone: string;
  smallText?: string | null;
  text?: string | null;
};

export const ContactInfo: FC<ContactInfoProps> = ({ phone, smallText, text }) => (
  <StyledBox backgroundColor={Colors.FadedGray} pyd={Spacing.L} pym={Spacing.M} textAlign="center">
    <GridContainer>
      <GridRow $isAlignedCenter>
        <GridColumn $push={3} $width={6}>
          {text && <StyledText>{text}</StyledText>}
          <ContactLink>
            <PhoneIconXs fill={Colors.Black} />
            <div>
              <StyledLink href={`tel:${phone}`}>{phone}</StyledLink>
            </div>
          </ContactLink>
          {smallText && <StyledText size="Xs">{smallText}</StyledText>}
        </GridColumn>
      </GridRow>
    </GridContainer>
  </StyledBox>
);
