'use client';

import { FC, use, useEffect, useState } from 'react';

import { Text } from '@lichtblick/photon/atoms/text/text';

import styles from './o-road-to-zero.module.scss';
import { ProgressContext } from './progress-context-wrapper';

const ANIMATION_DIVIDER = 200;

const formatNumber = (n: number): string => {
  const str = String(n * 10);

  return `${str.slice(0, -1) || 0},${str.slice(-1)}`;
};

export const Counter: FC<{ initialValue: number; unit1?: string; unit2?: string }> = ({
  initialValue,
  unit1,
  unit2,
}) => {
  const state = use(ProgressContext);
  const [animatedValue, setAnimatedValue] = useState(state?.[0] ?? initialValue);

  useEffect(() => {
    if (!state) {
      return;
    }

    const previousValue = animatedValue;
    const sign = previousValue < state[0] ? 1 : -1;
    const start = performance.now();
    const end = start + Math.abs(previousValue - state[0]) * ANIMATION_DIVIDER;

    const animate = (now: number) => {
      const elapsed = now - start;

      setAnimatedValue(Math.round((previousValue + (sign * elapsed) / ANIMATION_DIVIDER) * 10) / 10);

      if (now > end) {
        setAnimatedValue(state[0]);

        return;
      }

      requestAnimationFrame(animate);
    };

    animate(start);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.[0]]);

  return (
    <div className={styles.counter}>
      <Text bold className={styles.value} size="l">
        {formatNumber(animatedValue)}
        {unit1}
      </Text>
      <Text bold className={styles.unit} size="l">
        {unit2}
      </Text>
    </div>
  );
};
