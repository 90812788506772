import { Colors } from './colors';

export const light = {
  primary: Colors.Black,
  contrast: Colors.White,
};

export const dark = {
  primary: Colors.White,
  contrast: Colors.Black,
};

export const darkColors = [
  Colors.Black,
  Colors.DarkRed,
  Colors.Berry,
  Colors.DarkBerry,
  Colors.Aqua,
  Colors.DarkAqua,
  Colors.Smoke,
  Colors.DarkSmoke,
  // This is intended, as LightSmoke is defined to be a dark color,
  // that should have light text on it (as all other dark colors).
  Colors.LightSmoke,
  Colors.Green,
  Colors.DarkGreen,
  Colors.Moss,
  Colors.DarkMoss,
  Colors.DarkGray,
];

export const themes = {
  dark,
  light,
};
