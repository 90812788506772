import classNames from 'classnames';
import { ComponentPropsWithRef, forwardRef } from 'react';

import { RadioButton } from './radio-button';
import styles from './radio-input.module.scss';

import { FieldFooter } from '../field-footer/field-footer';
import { Label } from '../label/label';

export type RadioInputProps = Omit<ComponentPropsWithRef<'input'>, 'value' | 'name' | 'disabled'> & {
  hasBoldLabel?: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
  label: string;
  name: string;
  statusLabel?: string;
  value: boolean | number | string;
};

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ className, hasBoldLabel, hasError, isDisabled, label, name, statusLabel, value, ...props }, ref) => {
    const getState = () => {
      if (hasError) {
        return 'error';
      }

      if (isDisabled) {
        return 'disabled';
      }

      return 'default';
    };

    return (
      <Label
        bold={hasBoldLabel}
        className={classNames(styles.label, isDisabled && styles['label-disabled'], className)}
      >
        <RadioButton
          disabled={isDisabled}
          name={name}
          type="radio"
          value={value.toString()}
          {...props}
          ref={ref}
          state={getState()}
        />
        <span>
          {label}
          {statusLabel && <FieldFooter label={statusLabel} state={hasError ? 'error' : 'default'} />}
        </span>
      </Label>
    );
  },
);
