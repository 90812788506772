'use client';

import { FC, PropsWithChildren } from 'react';

import { CheckIconXs } from '@lichtblick/icons/svg/check/check-xs';

import {
  Root,
  StageContainer,
  ContentContainer,
  TextContainer,
  StyledListItem as StyledListItemImpl,
  H1Container,
  ImageContainer,
  SellingPointsSection,
  SellingPointsContainer,
  LogoItem,
  LogoItems,
} from './SolarStage.styles';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { SolarStageType } from '../../../types';
import { getColor } from '../../../utils';
import { AImage } from '../../AImage/AImage';
import { StyledMList } from '../../MList/MList.styles';
import { MLogoItem } from '../../MLogoItem/MLogoItem';
import { MTextIntro } from '../../MTextIntro/MTextIntro';
import { SoftFunnelEntryCard } from '../SoftFunnelEntry';

const TextIntro: FC<Pick<SolarStageType, 'textIntro'>> = ({ textIntro }) => {
  if (!textIntro?.length) {
    return null;
  }

  const { button: _, ...rest } = textIntro[0];

  return (
    <MTextIntro
      {...rest}
      accentColor="white"
      className={'text-intro'}
      headlineProps={{
        size: 'l',
        renderAs: 'h1',
      }}
      textProps={{
        renderAs: 'div',
        align: 'left',
      }}
    />
  );
};

const StyledListItem: FC<PropsWithChildren> = ({ children }) => (
  <StyledListItemImpl>
    <CheckIconXs />
    {children}
  </StyledListItemImpl>
);

const elementMappers: Partial<ElementMappers> = {
  ul: StyledMList,
  li: StyledListItem,
};

export const SolarStage: FC<SolarStageType> = ({ followingSectionTheme, logos, media, sellingPoints, textIntro }) => {
  const mediaItem = media?.[0]?.component === 'a-image' ? { ...media[0], imageDesktop: undefined } : undefined;

  return (
    <Root data-testid="solar-stage">
      <StageContainer>
        <ContentContainer>
          <TextContainer>
            {logos?.length && (
              <LogoItems $isVerticallyCentered={false}>
                {logos.map((item) => (
                  <LogoItem $length={logos.length} key={item._uid}>
                    <MLogoItem {...item} />
                  </LogoItem>
                ))}
              </LogoItems>
            )}
            <H1Container>
              {textIntro?.[0] && <TextIntro textIntro={textIntro} />}
              {sellingPoints && <RichText elementMappers={elementMappers} richText={sellingPoints} />}
            </H1Container>
          </TextContainer>
        </ContentContainer>
        {mediaItem && (
          <ImageContainer data-image-container>
            {mediaItem ? <AImage {...mediaItem} isBackground loading="eager" maxWidth={1920} /> : null}
          </ImageContainer>
        )}
      </StageContainer>
      <SellingPointsSection $backgroundColor={getColor(followingSectionTheme)}>
        <SellingPointsContainer>
          <SoftFunnelEntryCard theme="faded-gray" />
        </SellingPointsContainer>
      </SellingPointsSection>
    </Root>
  );
};
