import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';

import styles from './radio-button.module.scss';

export type RadioButtonState = 'default' | 'error' | 'disabled';

export type RadioButtonProps = InputHTMLAttributes<HTMLInputElement> & {
  state: RadioButtonState;
};

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(({ state, ...props }, ref) => (
  <input
    {...props}
    className={classNames('radioButton', styles['radio-button'], styles[`state-${state}`], props.className)}
    ref={ref}
    type="radio"
  />
));
