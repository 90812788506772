import classNames from 'classnames';
import {
  DetailedHTMLProps,
  HTMLAttributes,
  ElementType,
  KeyboardEventHandler,
  KeyboardEvent,
  useMemo,
  createRef,
  ReactNode,
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';

import styles from './drop-down.module.scss';

type KeyName = KeyboardEvent<Element>['key'];

export type DropDownOptionProps = {
  label?: ReactNode;
  value: string;
};

export type DropDownHandle = {
  close: () => void;
  open: () => void;
};

export type DropDownProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  onClose?: () => void;
  onOptionSelect: (value: string) => void;
  options: DropDownOptionProps[];
  renderAs?: ElementType | keyof JSX.IntrinsicElements;
  variant?: 'base' | 'input';
};

export const DropDown = forwardRef<DropDownHandle, DropDownProps>(
  ({ children, onClose, onKeyDown, onOptionSelect, options, renderAs = 'div', variant = 'base', ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const optionsRef = useRef<HTMLUListElement | null>(null);
    const optionRefs = useMemo(() => options.map(() => createRef<HTMLLIElement>()), [options]);
    const Tag = renderAs;

    const toggleIsOpen = useCallback(
      (isOpen: boolean) => {
        setIsOpen(isOpen);

        if (!isOpen) {
          onClose?.();
        }
      },
      [onClose],
    );

    useImperativeHandle(
      ref,
      () => ({
        close: () => toggleIsOpen(false),
        open: () => toggleIsOpen(true),
      }),
      [toggleIsOpen],
    );

    const focusOption = (index: number) => {
      const optionElement = optionRefs[index]?.current;

      if (optionElement) {
        optionElement?.focus();
        optionsRef.current?.scrollTo({
          behavior: 'smooth',
          top:
            optionElement.offsetTop -
            (optionsRef.current.getBoundingClientRect().height - optionElement.getBoundingClientRect().height) / 2,
        });
      }

      toggleIsOpen(index >= 0);
    };

    const clearInput = (): void => {
      focusOption(-1);
      onOptionSelect('');
    };

    const handleKey = (event: KeyboardEvent<HTMLElement>, actions: Partial<Record<KeyName, () => void>>) => {
      const action = actions[event.key];

      if (action) {
        event.preventDefault();
        event.stopPropagation();
        action();
      }

      onKeyDown?.(event);
    };

    const handleOuterKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
      const moveDown = () => focusOption(0);
      const moveUp = () => focusOption(options.length - 1);

      handleKey(event, {
        ArrowUp: moveUp,
        ArrowDown: moveDown,
        Escape: clearInput,
      });
    };

    const handleInnerKeyDown = (index: number, event: KeyboardEvent<HTMLElement>) => {
      const moveDown = () => focusOption((index + 1) % options.length);
      const moveUp = () => focusOption(index - 1);
      const selectCurrent = () => onOptionSelect(options[index].value);
      const closeDropdown = () => toggleIsOpen(false);

      handleKey(event, {
        ArrowUp: moveUp,
        ArrowDown: moveDown,
        Enter: selectCurrent,
        ' ': selectCurrent,
        Tab: closeDropdown,
      });
    };

    return (
      <Tag
        {...props}
        className={classNames('drop-down', styles[`variant-${variant}-root`], props.className)}
        data-open={isOpen || undefined}
        onKeyDown={handleOuterKeyDown}
      >
        {children}
        <div className={styles[`variant-${variant}-container`]}>
          <div className={styles[`variant-${variant}-content`]}>
            <ul className={styles[`variant-${variant}-options`]} data-testid="options" ref={optionsRef}>
              {options.length === 0 && (
                <li className={styles[`variant-${variant}-no-result`]}>Dazu wurde leider kein Eintrag gefunden.</li>
              )}
              {options.map(({ label, value }, index) => (
                <li
                  aria-selected={false}
                  className={styles[`variant-${variant}-option`]}
                  key={label + value}
                  onClick={() => onOptionSelect(value)}
                  onKeyDown={(event) => handleInnerKeyDown(index, event)}
                  ref={optionRefs[index]}
                  role="option"
                  tabIndex={-1}
                >
                  {label ?? value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Tag>
    );
  },
);
