import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/AButton/AButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AListItem"] */ "/vercel/path0/packages/cms/components/AListItem/AListItem.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/AVideo/AVideo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/BenefitCards/BenefitCards.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/CasesSlider/CasesSlider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/CaseStudy/CaseStudy.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/CompressedProductBundle/CompressedProductBundle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FourSteps"] */ "/vercel/path0/packages/cms/components/eaas/FourSteps/FourSteps.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/LeadForm/LeadForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Promo"] */ "/vercel/path0/packages/cms/components/eaas/Promo/Promo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/SoftFunnelEntry/SoftFunnelEntry.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/SolarCheckoutTeaser/SolarCheckoutTeaser.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/SolarProductBundle/SolarProductBundle.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/SolarSlider/SolarSlider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/SolarStage/SolarStage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StromWalletTextIntro"] */ "/vercel/path0/packages/cms/components/eaas/StromWalletTextIntro.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/TabsWithTextIntros/TabsWithTextIntros.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/eaas/TabsWithTextIntros/TextIntroWithTabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrimarySubNavigation"] */ "/vercel/path0/packages/cms/components/Footer/PrimarySubNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecondaryNavigation"] */ "/vercel/path0/packages/cms/components/Footer/SecondaryNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/packages/cms/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KWKForm"] */ "/vercel/path0/packages/cms/components/KWKForm/KWKForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactInfo"] */ "/vercel/path0/packages/cms/components/Layout/ContactInfo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/Layout/exit-preview-button.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/AImage/AImage.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/AImage/Picture.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MAccordionItem"] */ "/vercel/path0/packages/cms/components/MAccordionItem/MAccordionItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LottiePlayer"] */ "/vercel/path0/packages/cms/components/MAnimatedIllustration/lottie-player.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MBreadcrumb/m-breadcrum.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MColumnItem"] */ "/vercel/path0/packages/cms/components/MColumnItem/MColumnItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MDownloadItem"] */ "/vercel/path0/packages/cms/components/MDownloadItem/MDownloadItem.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MFootnote/MFootnote.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MIframe"] */ "/vercel/path0/packages/cms/components/MIframe/MIframe.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MNavigationSecondary/MNavigationSecondary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SectionWithScrollHandling"] */ "/vercel/path0/packages/cms/components/MRoadToZeroItem/section-with-scroll-handling.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Root","DataPrivacyLayer","PrivacyText","Iframe"] */ "/vercel/path0/packages/cms/components/MYoutube/MYoutube.styles.ts");
;
import(/* webpackMode: "eager", webpackExports: ["OptinSwitcher"] */ "/vercel/path0/packages/cms/components/MYoutube/optin-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrivacyButton"] */ "/vercel/path0/packages/cms/components/MYoutube/privacy-button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OAccordion/o-accordion.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MTextIntro/m-text-intro.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CalculatorLayout"] */ "/vercel/path0/packages/cms/components/OAllInOneCalculator/calculator-layout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OAllInOneCalculator/calculator-maintenance.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["HeatingCalculator"] */ "/vercel/path0/packages/cms/components/OAllInOneCalculator/HeatingCalculator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeChargingCalculator"] */ "/vercel/path0/packages/cms/components/OAllInOneCalculator/HomeChargingCalculator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HouseholdCalculator"] */ "/vercel/path0/packages/cms/components/OAllInOneCalculator/HouseholdCalculator.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OAllInOneCalculator/o-all-in-one-calculator.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SolarTeaser"] */ "/vercel/path0/packages/cms/components/OAllInOneCalculator/SolarTeaser.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OCarCalculator/o-car-calculator.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OCarousel/o-carousel.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Slider"] */ "/vercel/path0/packages/cms/components/OCarousel/slider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OColumns/o-columns.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/ODownload/o-download.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OEAutoTariffComparison/OEAutoTariffComparison.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OGeigForm"] */ "/vercel/path0/packages/cms/components/OGeigForm/OGeigForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OHeyFlowFormScript"] */ "/vercel/path0/packages/cms/components/OHeyFlowForm/o-hey-flow-form-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HubSpotFormScript"] */ "/vercel/path0/packages/cms/components/OHubSpotForm/hub-spot-form-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Expander"] */ "/vercel/path0/packages/cms/components/OIndex/Expander.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SectionWithScrollHandling"] */ "/vercel/path0/packages/cms/components/OIndex/section-with-scroll-handling.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OProductComparison/OProductComparison.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OProductOverview/OProductOverview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OptinSwitcher"] */ "/vercel/path0/packages/cms/components/OReferral/optin-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrivacyButton"] */ "/vercel/path0/packages/cms/components/OReferral/privacy-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Counter"] */ "/vercel/path0/packages/cms/components/ORoadToZero/counter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressContextWrapper"] */ "/vercel/path0/packages/cms/components/ORoadToZero/progress-context-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Equota"] */ "/vercel/path0/packages/cms/components/OSnowflake/Equota/Equota.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Gapminder"] */ "/vercel/path0/packages/cms/components/OSnowflake/Gapminder/Gapminder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Badge"] */ "/vercel/path0/packages/cms/components/OStageFull/Badge.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OSuccessPageContent/OSuccessPageContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleLogo","TrustpilotLogo"] */ "/vercel/path0/packages/cms/components/OSuccessRating/svg/logos.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OTable/OTable.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OTariffComparison/OTariffComparison.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TeaserGridArticlesWithSelect"] */ "/vercel/path0/packages/cms/components/OTeaserGridArticles/TeaserGridArticlesWithSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SectionWithDisplayHandling"] */ "/vercel/path0/packages/cms/components/OToast/section-with-display-handling.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OWallboxConfigurator/index.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OWallboxConfigurator/OWallboxConfigurator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GridContainer","GridRow","GridColumn"] */ "/vercel/path0/packages/cms/components/shared/GridContainer/GridContainer.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TArticle/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TDetail/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/THome/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TJobDetail/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TNotification/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TOverview/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TPressDetail/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TProduct/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TReferral/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TemplateTracker"] */ "/vercel/path0/packages/cms/components/TStory/TemplateTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStyle","Header","StyledLink","Footer"] */ "/vercel/path0/packages/cms/components/TStory/TStory.styles.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/icons/components/Icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LazySvgMap"] */ "/vercel/path0/packages/icons/components/lazy-svg-map.ts");
;
import(/* webpackMode: "eager", webpackExports: ["AlertIconM"] */ "/vercel/path0/packages/icons/svg/alert/alert-m.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ArrowRightIconXs"] */ "/vercel/path0/packages/icons/svg/arrow-right/arrow-right-xs.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ChargingStationIconM"] */ "/vercel/path0/packages/icons/svg/charging-station/charging-station-m.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ChevronRightIconXs"] */ "/vercel/path0/packages/icons/svg/chevron-right/chevron-right-xs.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CloseIconS"] */ "/vercel/path0/packages/icons/svg/close/close-s.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CounterReadingIconS"] */ "/vercel/path0/packages/icons/svg/counter-reading/counter-reading-s.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ElectricityIconM"] */ "/vercel/path0/packages/icons/svg/electricity/electricity-m.ts");
;
import(/* webpackMode: "eager", webpackExports: ["GasIconM"] */ "/vercel/path0/packages/icons/svg/gas/gas-m.ts");
;
import(/* webpackMode: "eager", webpackExports: ["HeatingEnergyIconM"] */ "/vercel/path0/packages/icons/svg/heating-energy/heating-energy-m.ts");
;
import(/* webpackMode: "eager", webpackExports: ["LogoIcon"] */ "/vercel/path0/packages/icons/svg/logo/logo.ts");
;
import(/* webpackMode: "eager", webpackExports: ["MailIconXs"] */ "/vercel/path0/packages/icons/svg/mail/mail-xs.ts");
;
import(/* webpackMode: "eager", webpackExports: ["OpenIconS"] */ "/vercel/path0/packages/icons/svg/open/open-s.ts");
;
import(/* webpackMode: "eager", webpackExports: ["PhoneIconXs"] */ "/vercel/path0/packages/icons/svg/phone/phone-xs.ts");
;
import(/* webpackMode: "eager", webpackExports: ["SolarIconM"] */ "/vercel/path0/packages/icons/svg/solar/solar-m.ts");
;
import(/* webpackMode: "eager", webpackExports: ["UserIconM"] */ "/vercel/path0/packages/icons/svg/user/user-m.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ddLogger"] */ "/vercel/path0/packages/logger/instances/dataDogLogger.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/page-container/page-container.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/box-cta/box-cta.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/headline/headline.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/logo/logo.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/text-cta/text-cta.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/text/text.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/image/image.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/list/list.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["TeaserItemLink"] */ "/vercel/path0/packages/photon/molecules/teaser-item/teaser-item-link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/molecules/teaser-item/teaser-item.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/chip/chip.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/loader/loader.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/atoms/accent-text/accent-text.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OEditorial/o-editorial.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/AText/a-text.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MAnimatedIllustration/m-animated-illustration.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MList/m-list.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OFactGrid/o-fact-grid.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MFactItem/m-fact-item.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OFootnotes/o-footnotes.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OForm/o-form.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OIndex/o-index.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MIndexItem/m-index-item.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OInfoteaser/o-info-teaser.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OList/o-list.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OLogos/o-logos.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OMedia/media-figure.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OMedia/o-media.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MCalculatorBox/MCalculatorBox.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/shared/AnchorMark/anchor-mark.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OStageArticle/m-stage-article-info-line.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OStageArticle/o-stage-article.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OStandoutText/o-standout-text.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OTeaserGrid/o-teaser-grid.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OTeaserList/o-teaser-list.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MTeaserListItem/m-teaser-list-item.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OText/o-text.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OHubSpotForm/o-hub-spot-form.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OStageFull/o-stage-full.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OStageNarrow/o-stage-narrow.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OStageSplit/o-stage-narrow.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/ORoadToZero/o-road-to-zero.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/MRoadToZeroItem/m-road-to-zero-item.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OSuccessRating/o-success-rating.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OHeyFlowForm/o-hey-flow-form.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OToast/o-toast.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OReferral/o-referral.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/cms/components/OStoryPage/o-story-page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/photon/organisms/accordion/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/vercel/path0/packages/ui-components/atoms/Box/Box.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/vercel/path0/packages/ui-components/atoms/Video/Video.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui-components/molecules/AppBadges/AppBadges.tsx");
