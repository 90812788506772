'use client';

import { FC } from 'react';

import { Text } from '@lichtblick/ui-components';

import { TechnicalDetailsItem } from './ATechnicalDetailsItem.styles';

import { ATechnicalDetailsItemType } from '../../types/storyblok';

type Props = ATechnicalDetailsItemType & { className?: string };

export const ATechnicalDetailsItem: FC<Props> = ({ className, label, property }) => (
  <TechnicalDetailsItem className={className}>
    <Text isBold size="L">
      {label}
    </Text>
    <Text data-property={property} size="L">
      {property}
    </Text>
  </TechnicalDetailsItem>
);
