import styled, { css } from '@lichtblick/styled';
import { Breakpoints, Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

import { StageCard } from '../SoftFunnelEntry/SoftFunnelEntry.styles';

export const Root = styled.section`
  display: flex;
  flex-direction: column;
`;

export const LogoItems = styled.div<{ $isVerticallyCentered?: boolean }>`
  display: flex;
  align-items: ${({ $isVerticallyCentered }) => ($isVerticallyCentered ? 'center' : 'normal')};
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -${Spacing.Xxs};
`;

export const LogoItem = styled.div<{ $length: number }>`
  flex: 0 0 calc(50% - ${Spacing.Xs});
  margin: 0 ${Spacing.Xxs};

  ${({ $length }) =>
    // TODO: what does this magic do? Force wrap after certain amount of items? Can't we improve this with e.g. grid?
    [5, 6, 9].includes($length) &&
    css`
      ${MediaMediumAndAbove} {
        &:nth-child(3) {
          margin-right: calc(${Spacing.Xxs} + 1px);
        }
        &:nth-child(1) {
          margin-left: calc(${Spacing.Xxs} + 1px);
        }
      }
    `}

  ${({ $length }) =>
    $length === 9 &&
    css`
      ${MediaMediumAndAbove} {
        &:nth-child(6) {
          margin-right: calc(${Spacing.Xxs} + 1px);
        }
        &:nth-child(4) {
          margin-left: calc(${Spacing.Xxs} + 1px);
        }
      }
    `}
    
  ${MediaMediumAndAbove} {
    flex-basis: calc(25% - ${Spacing.Xs});
  }
`;

export const StageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  /* stylelint-disable-next-line meowtec/no-px */
  ${MediaMediumAndAbove} {
    /* stylelint-disable-next-line meowtec/no-px */
    height: 600px;
    flex-direction: row;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${Colors.Moss};

  ${MediaMediumAndAbove} {
    width: 50%;
    justify-content: flex-end;
  }
`;

export const SellingPointsSection = styled.section<{ $backgroundColor?: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor || Colors.FadedGray};
`;

export const SellingPointsContainer = styled(Container)`
  margin-bottom: -4.5rem;
  ${StageCard} {
    transform: translateY(-4.5rem);
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: var(--max-content-width, ${Breakpoints.Large}px);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.M};
  padding: 2rem;
  padding-inline: ${Spacing.Xs};
  padding-bottom: 8rem;

  ${MediaMediumAndAbove} {
    max-width: calc(var(--max-content-width, ${Breakpoints.Large}px) / 2);
    padding-inline: ${Spacing.Xl};
    padding-top: 4rem;
    justify-content: space-between;
  }

  .text-intro {
    color: ${Colors.White};
  }

  ${LogoItems} {
    display: none;
    justify-content: flex-start;
    flex-wrap: nowrap;

    ${MediaSmallAndAbove} {
      display: flex;
      max-width: 50%;
    }
    ${MediaMediumAndAbove} {
      max-width: 70%;
    }
  }

  ${LogoItem} {
    flex-basis: auto;
    flex-shrink: 1;
  }

  .headline {
    /* stylelint-disable-next-line meowtec/no-px */
    font-size: 24px;
    ${MediaSmallAndAbove} {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 40px;
    }
    ${MediaMediumAndAbove} {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 42px;
    }
  }
`;

export const StyledListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  color: white;
  gap: ${Spacing.Xs};
  width: 100%;
  br {
    display: none;
  }
`;

export const H1Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${Spacing.S};
`;

export const ImageContainer = styled.div`
  width: 100%;
  /* stylelint-disable-next-line meowtec/no-px */
  height: 180px;
  justify-content: center;
  align-items: center;

  ${MediaMediumAndAbove} {
    height: 100%;
    width: 50%;
    display: flex;
  }
`;
