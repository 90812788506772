import classNames from 'classnames';
import { ComponentPropsWithRef, FC } from 'react';

import styles from './checkbox.module.scss';

export type CheckboxState = 'default' | 'error' | 'disabled';

export type CheckboxElementProps = Omit<ComponentPropsWithRef<'input'>, 'type'>;

export type CheckboxProps = CheckboxElementProps & {
  className?: string;
  state?: CheckboxState;
};

export const Checkbox: FC<CheckboxProps> = ({ className, state = 'default', ...props }) => (
  <input type="checkbox" {...props} className={classNames('checkbox', styles.checkbox, styles[state], className)} />
);
