'use client';

import Script from 'next/script';

import { Colors, Shades, Spacing } from '@lichtblick/theme';
import { Box, Container, Headline, Image, Text } from '@lichtblick/ui-components';

import { AquaHeadline, Badge, FormContainer, MainContainer, RichTextWrapper } from './OGeigForm.styles';

import { RichText } from '../../helpers/RichText';
import { OGeigFormType } from '../../types';

export const OGeigForm = ({
  formDescription,
  formTitle,
  image,
  leftBadge,
  leftDescription,
  leftTitle,
}: OGeigFormType) => {
  return (
    <Container>
      <MainContainer>
        <Box backgroundColor={Shades.Aqua700} flex="1">
          {Boolean(image) && <Image description={image.alt || ''} url={{ mobile: image.filename }} />}

          <Box
            alignItems="flex-start"
            display="flex"
            flex="1"
            flexDirection="column"
            gap="1.2rem"
            px={Spacing.S}
            py={Spacing.S}
          >
            <AquaHeadline size="M">{leftTitle}</AquaHeadline>

            <Badge>{leftBadge}</Badge>

            {leftDescription !== undefined && (
              <RichTextWrapper>
                <RichText richText={leftDescription} />
              </RichTextWrapper>
            )}
          </Box>
        </Box>

        <Box
          backgroundColor={Colors.FadedGray}
          display="flex"
          flex="1"
          flexDirection="column"
          gap="1rem"
          px="3rem"
          py="4rem"
        >
          <Headline>{formTitle}</Headline>
          <Text>{formDescription}</Text>
          <FormContainer id="geig-form" />
        </Box>
      </MainContainer>

      <Script
        id="geig-form-script"
        onReady={() => {
          (window as any).hbspt.forms.create({
            region: 'eu1',
            portalId: '143379264',
            formId: 'fdbf4711-ae03-428b-9358-2acff6602b51',
            target: '#geig-form',
          });
        }}
        src="//js-eu1.hsforms.net/forms/embed/v2.js"
        type="text/javascript"
      />
    </Container>
  );
};
