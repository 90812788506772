/* eslint-disable react/no-array-index-key */
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Image } from '@lichtblick/photon/atoms/image/image';
import { Li, Ul, Ol } from '@lichtblick/photon/atoms/list/list';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { Video } from '@lichtblick/ui-components/atoms/Video/Video';

import { isRichText } from './isRichtext';

import {
  RichTextBlockType,
  RichTextDocType,
  RichTextLinkMarkType,
  RichTextTextMarkType,
  RichTextTextType,
} from '../types/storyblok';

export type ElementMappers = {
  a: FC<PropsWithChildren<{ href?: string }>>;
  b: FC<PropsWithChildren>;
  blockquote: FC<PropsWithChildren>;
  code: FC<PropsWithChildren>;
  /** Container for inline-embedded entries */
  embed: FC<PropsWithChildren>;
  /** Container for block-embedded entries */
  embedBlock: FC<PropsWithChildren>;
  h1: FC<PropsWithChildren>;
  h2: FC<PropsWithChildren>;
  h3: FC<PropsWithChildren>;
  h4: FC<PropsWithChildren>;
  h5: FC<PropsWithChildren>;
  h6: FC<PropsWithChildren>;
  hr: FC;
  i: FC<PropsWithChildren>;
  img: FC<{ alt: string; src: string }>;
  li: FC<PropsWithChildren<{ i: number }>>;
  ol: FC<PropsWithChildren>;
  p: FC<PropsWithChildren>;
  s: FC<PropsWithChildren>;
  sub: FC<PropsWithChildren>;
  sup: FC<PropsWithChildren>;
  table: FC<PropsWithChildren>;
  tbody: FC<PropsWithChildren>;
  td: FC<PropsWithChildren>;
  th: FC<PropsWithChildren>;
  tr: FC<PropsWithChildren>;
  u: FC<PropsWithChildren>;
  ul: FC<PropsWithChildren>;
  video: FC<{ alt: string; src: string }>;
};

const defaultElementMappers: ElementMappers = {
  b: ({ children }) => <strong>{children}</strong>,
  i: ({ children }) => <em>{children}</em>,
  u: ({ children }) => <u>{children}</u>,
  sup: ({ children }) => <sup>{children}</sup>,
  sub: ({ children }) => <sub>{children}</sub>,
  code: ({ children }) => <code>{children}</code>,
  s: ({ children }) => <s>{children}</s>,
  h1: ({ children }) => (
    <Headline renderAs="h1" size="l">
      {children}
    </Headline>
  ),
  h2: ({ children }) => (
    <Headline renderAs="h2" size="m">
      {children}
    </Headline>
  ),
  h3: ({ children }) => (
    <Headline renderAs="h3" size="s">
      {children}
    </Headline>
  ),
  h4: ({ children }) => (
    <Headline renderAs="h4" size="s">
      {children}
    </Headline>
  ),
  h5: ({ children }) => (
    <Headline renderAs="h5" size="s">
      {children}
    </Headline>
  ),
  h6: ({ children }) => (
    <Headline renderAs="h6" size="s">
      {children}
    </Headline>
  ),
  p: ({ children }) => (
    <Text renderAs="p" size="s" spacing>
      {children}
    </Text>
  ),
  ol: ({ children }) => <Ol>{children}</Ol>,
  ul: ({ children }) => <Ul>{children}</Ul>,
  li: ({ children }) => <Li>{children}</Li>,
  blockquote: ({ children }) => <blockquote>{children}</blockquote>,
  hr: () => <hr />,
  table: ({ children }) => <table>{children}</table>,
  tbody: ({ children }) => <tbody>{children}</tbody>,
  tr: ({ children }) => <tr>{children}</tr>,
  th: ({ children }) => <th>{children}</th>,
  td: ({ children }) => <td>{children}</td>,
  a: ({ children, href = '#' }) => {
    return (
      <TextCTA as="a" href={href} inlineLink target={href.startsWith('http') ? '_blank' : '_self'}>
        {children}
      </TextCTA>
    );
  },
  embed: ({ children }) => <>{children}</>,
  embedBlock: ({ children }) => <>{children}</>,
  img: ({ alt, src }) => <Image description={alt} url={{ mobile: src }} />,
  video: ({ alt, src }) => <Video description={alt} urls={{ mobile: [src] }} />,
};

const markMap = {
  bold: 'b',
  code: 'code',
  italic: 'i',
  underline: 'u',
  subscript: 'sub',
  superscript: 'sup',
  strike: 's',
} as const;

const isLinkMark = (mark: RichTextTextType['marks'][number]): mark is RichTextLinkMarkType => mark.type === 'link';

const isTextMark = (mark: RichTextTextType['marks'][number]): mark is RichTextTextMarkType =>
  (Object.keys(markMap) as string[]).includes(mark.type);

const RichTextElement: FC<{ el: RichTextBlockType; index: number; mappers: ElementMappers }> = ({
  el,
  index,
  mappers,
}): ReactNode => {
  let text: ReactNode;
  let linkMark: RichTextLinkMarkType | undefined;
  let textMark: RichTextTextMarkType | undefined;
  let TextComponent;

  switch (el.type) {
    case 'paragraph':
      return (
        <mappers.p>
          {el.content?.map((child, i) => <RichTextElement el={child} index={i} key={i} mappers={mappers} />)}
        </mappers.p>
      );

    case 'hard_break':
      return <br />;

    case 'text':
      linkMark = el.marks?.find(isLinkMark);

      if (linkMark) {
        if (linkMark.attrs.linktype === 'email') {
          return <mappers.a href={`mailto:${linkMark.attrs.href}`}>{el.text}</mappers.a>;
        }

        return <mappers.a href={linkMark.attrs.href}>{el.text}</mappers.a>;
      }

      text = el.text.split('\n').map((str, index) => (
        <Fragment key={`${str}${index}`}>
          {index > 0 && <br />}
          {str}
        </Fragment>
      ));

      textMark = el.marks?.find<RichTextTextMarkType>(isTextMark);

      if (!textMark) {
        return <Fragment>{text}</Fragment>;
      }

      TextComponent = mappers[markMap[textMark.type]] as ElementMappers[(typeof markMap)[RichTextTextMarkType['type']]];

      return <TextComponent>{el.text}</TextComponent>;

    case 'ordered_list':
      return (
        <mappers.ol>
          {el.content?.map((child, i) => <RichTextElement el={child} index={i} key={i} mappers={mappers} />)}
        </mappers.ol>
      );

    case 'bullet_list':
      return (
        <mappers.ul>
          {el.content?.map((child, i) => <RichTextElement el={child} index={i} key={i} mappers={mappers} />)}
        </mappers.ul>
      );

    case 'list_item':
      return (
        <mappers.li i={index}>
          {el.content?.map((child, i) => <RichTextElement el={child} index={i} key={i} mappers={mappers} />)}
        </mappers.li>
      );

    case 'heading':
      TextComponent = mappers[`h${el.attrs.level}`];

      return (
        <TextComponent>
          {el.content?.map((child, i) => <RichTextElement el={child} index={i} key={i} mappers={mappers} />)}
        </TextComponent>
      );

    case 'image':
      return <mappers.img alt={el.attrs.alt} src={el.attrs.src} />;
  }
};

export const RichText: React.FC<{ elementMappers?: Partial<ElementMappers>; richText?: RichTextDocType }> = ({
  elementMappers,
  richText,
}) => {
  if (!isRichText(richText)) {
    return null;
  }

  const mappers = { ...defaultElementMappers, ...elementMappers };

  return (
    <>{richText?.content?.map((child, i) => <RichTextElement el={child} index={i} key={i} mappers={mappers} />)}</>
  );
};

const mapRichTextToHtmlElement = (el: RichTextBlockType): string => {
  let text: ReactNode;
  let linkMark: RichTextLinkMarkType | undefined;
  let textMark: RichTextTextMarkType | undefined;

  switch (el.type) {
    case 'paragraph':
      return `<p>${el.content?.map(mapRichTextToHtmlElement).join('') ?? ''}</p>`;

    case 'hard_break':
      return '<br/>';

    case 'text':
      linkMark = el.marks?.find(isLinkMark);

      if (linkMark) {
        return `<a href="${linkMark.attrs.href}">${el.text}</a>`;
      }

      text = el.text.replaceAll('\n', '<br/>');

      textMark = el.marks?.find<RichTextTextMarkType>(isTextMark);

      return textMark ? `<${markMap[textMark.type]}>${text}</${markMap[textMark.type]}>` : `${text}`;

    case 'ordered_list':
      return `<ol>${el.content?.map(mapRichTextToHtmlElement).join('') ?? ''}</ol>`;

    case 'bullet_list':
      return `<ul>${el.content?.map(mapRichTextToHtmlElement).join('') ?? ''}</ul>`;

    case 'list_item':
      return `<li>${el.content?.map(mapRichTextToHtmlElement).join('') ?? ''}</li>`;

    case 'heading':
      return `<h${el.attrs.level}>${el.content?.map(mapRichTextToHtmlElement).join('') ?? ''}</h${el.attrs.level}>`;

    default:
      return '';
  }
};

export const richTextToHtmlMapper = (richText: RichTextDocType): string => {
  return richText?.content?.map(mapRichTextToHtmlElement).join('') ?? '';
};

const mapRichTextToString = (el: RichTextBlockType): string => {
  switch (el.type) {
    case 'paragraph':
      return `${el.content?.map(mapRichTextToString).join('') ?? ''} `;

    case 'hard_break':
      return ' ';

    case 'text':
      return el.text;

    case 'ordered_list':
      return `${el.content?.map(mapRichTextToString).join('') ?? ''} `;

    case 'bullet_list':
      return `${el.content?.map(mapRichTextToString).join('') ?? ''} `;

    case 'list_item':
      return `${el.content?.map(mapRichTextToString).join('') ?? ''} `;

    case 'heading':
      return `${el.content?.map(mapRichTextToString).join('') ?? ''} `;

    default:
      return '';
  }
};

export const richTextToString = (richText: RichTextDocType | undefined): string => {
  return `${richText?.content?.map(mapRichTextToString).join('') ?? ''} `.trim().replaceAll(/\s{2,}/g, ' ');
};

export const isRichTextEmpty = (richText: RichTextDocType | undefined): richText is RichTextDocType => {
  return !richText?.content?.length;
};
