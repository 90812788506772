'use client';

import { FC } from 'react';

import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';

import { TReferralType } from '../../types/storyblok';

export const PrivacyButton: FC<Pick<TReferralType, 'privacyButton'>> = ({ privacyButton }) =>
  privacyButton ? (
    <BoxCTA
      as="button"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.__ucCmp?.showSecondLayer();
      }}
      variant="neutral"
    >
      {privacyButton}
    </BoxCTA>
  ) : null;
