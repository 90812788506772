import classNames from 'classnames';
import { ComponentPropsWithRef, ElementType, forwardRef, ReactNode } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';

import styles from './textfield.module.scss';

import { Text } from '../../atoms/text/text';
import { FieldFooter, FieldFooterState } from '../field-footer/field-footer';
import { Label } from '../label/label';
import { Tooltip } from '../tooltip/tooltip';

export type InputElementProps = {
  bold?: boolean;
  centered?: boolean;
};

export type TextfieldProps = Omit<ComponentPropsWithRef<'input'>, 'disabled'> &
  InputElementProps & {
    className?: string;
    'data-testid'?: string;
    disabled?: boolean;
    icon?: ElementType;
    label?: string;
    leftSlot?: ReactNode;
    onTooltipClick?: () => void;
    rightSlot?: ReactNode;
    statusLabel?: string;
    statusType?: FieldFooterState;
    subLabel?: string;
    suffixText?: string;
    tooltipContent?: ReactNode;
  };

/**
 * Generic text input component with some basic setup to be used as foundation for all kinds of text input types.
 * Ref needs to be forwarded to be compatible with form packages like react-hook-form.
 */
export const Textfield = forwardRef<HTMLInputElement, TextfieldProps>(
  (
    {
      bold: isBold,
      centered: isCentered,
      className,
      'data-testid': testId,
      disabled: isDisabled,
      icon,
      id,
      label,
      leftSlot,
      name,
      onTooltipClick,
      rightSlot,
      statusLabel,
      statusType = 'default',
      subLabel,
      suffixText,
      tooltipContent,
      ...props
    },
    ref,
  ) => {
    const inputId = id || name;
    const labelId = inputId && `${inputId}_label`;
    const statusId = inputId && `${inputId}_validationMessage`;

    return (
      <div className={classNames(styles.wrapper, className)}>
        {Boolean(label || onTooltipClick || tooltipContent) && (
          <div className={styles['label-wrapper']}>
            {Boolean(label) && (
              <Label bold htmlFor={inputId} id={labelId}>
                {label}
                {Boolean(subLabel) && (
                  <Text className={styles['sub-label']} size="xs">
                    {subLabel}
                  </Text>
                )}
              </Label>
            )}
            {(Boolean(onTooltipClick) || Boolean(tooltipContent)) && (
              <Tooltip content={tooltipContent}>
                <InfoIconXs className={styles['info-icon']} onClick={onTooltipClick} />
              </Tooltip>
            )}
          </div>
        )}
        <div
          className={classNames(
            'input-row',
            styles['input-row'],
            isDisabled && styles['input-row-disabled'],
            statusType !== 'default' && styles[`input-row-${statusType}`],
          )}
        >
          {leftSlot && <div className={styles['slot-left']}>{leftSlot}</div>}
          <input
            {...props}
            aria-describedby={statusId}
            aria-invalid={statusType === 'error'}
            aria-labelledby={labelId}
            className={classNames(
              styles.input,
              Boolean(rightSlot) && styles['input-right-slot'],
              Boolean(leftSlot) && styles['input-left-slot'],
              isCentered && styles['input-center'],
              isBold && styles['input-bold'],
            )}
            data-testid={testId || inputId}
            disabled={isDisabled}
            id={inputId}
            name={name}
            ref={ref}
          />
          {Boolean(rightSlot || suffixText) && (
            <div className={styles['slot-right']}>
              {Boolean(suffixText) && (
                <Text bold className={styles['suffix-text']}>
                  {suffixText}
                </Text>
              )}
              {rightSlot}
            </div>
          )}
        </div>
        {Boolean(statusLabel) && <FieldFooter icon={icon} id={statusId} label={statusLabel} state={statusType} />}
      </div>
    );
  },
);
