'use client';

import { FC } from 'react';

import { Colors, Spacing } from '@lichtblick/theme';
import { TabsType, TextIntro, Tabs, Text } from '@lichtblick/ui-components';

import { FlexContainer, ImageWrapper, StyledHeadline, SvgWrapper } from './TabsWithTextIntros.styles';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { TextIntroWithTabsType } from '../../../types';
import { getColor } from '../../../utils';
import { AImage } from '../../AImage';
import { isSvgImage } from '../../AImage/Picture.helpers';
import { AnchorMark } from '../../shared';
import { Section, HalfSizedColumn } from '../shared';

const elementMappers: Partial<ElementMappers> = {
  h1: ({ children }) => (
    <StyledHeadline renderAs="h1" size="S">
      {children}
    </StyledHeadline>
  ),
  h3: ({ children }) => (
    <StyledHeadline renderAs="h3" size="S">
      {children}
    </StyledHeadline>
  ),
  p: ({ children }) => (
    <Text renderAs="p" size="M">
      {children}
    </Text>
  ),
};

export const TextIntroWithTabs: FC<TextIntroWithTabsType> = ({ chip, headline, richText, tabs, theme }) => {
  const chipProps = chip ? { label: chip } : undefined;

  const uiTabs = tabs.map(({ image, label, subline }) => ({
    contentSlot: isSvgImage(image[0].imageMobile.filename) ? (
      <SvgWrapper>
        <AImage {...image[0]} />
      </SvgWrapper>
    ) : (
      <ImageWrapper>
        <AImage {...image[0]} />
      </ImageWrapper>
    ),
    headline: label,
    subline,
  })) as TabsType;

  return (
    <Section backgroundColor={getColor(theme)}>
      <AnchorMark anchorId={'Vergleich'} />
      <FlexContainer>
        <TextIntro
          chipProps={chipProps}
          headline={headline}
          text={richText && <RichText elementMappers={elementMappers} richText={richText} />}
        />
        <HalfSizedColumn>
          <Tabs
            backgroundColor={theme === 'white' ? Colors.BackgroundGray : Colors.White}
            gap={Spacing.S}
            tabs={uiTabs}
          />
        </HalfSizedColumn>
      </FlexContainer>
    </Section>
  );
};
