'use client';

import { Dispatch, SetStateAction } from 'react';

import { formatNumber } from '@lichtblick/localization';
import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { Headline } from '@lichtblick/photon/atoms/headline/headline';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { Modal } from '@lichtblick/photon/organisms/modal/modal';

import styles from './calculator-layout.module.scss';
import { usageLimitElectricity, usageLimitGas } from './constants';

type UsageModalProps = {
  readonly isOpen: boolean;
  readonly route: '/gewerbestrom' | '/gewerbegas';
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const UsageModal = ({ isOpen, route, setIsOpen }: UsageModalProps) => {
  const isElectricity = route === '/gewerbestrom';
  const energyName = isElectricity ? 'Strom' : 'Gas';

  return (
    <Modal
      footerContent={
        <>
          <BoxCTA as="a" className={styles['modal-box-cta']} href={route} variant="primary">
            Mehr erfahren
          </BoxCTA>
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsOpen(false)}>
            Schließen
          </TextCTA>
        </>
      }
      isOpen={isOpen}
      onPointerDownOutside={() => setIsOpen(false)}
      title="Mehr erfahren"
    >
      <div className={styles['modal-content']}>
        <Headline renderAs="h2" size="s">
          Für dich lohnt sich Gewerbe{energyName}
        </Headline>
        <Text renderAs="p">
          Mit einem {energyName}verbrauch ab{' '}
          {isElectricity ? formatNumber(usageLimitElectricity.max + 1) : formatNumber(usageLimitGas.max + 1)} kWh fällst
          du unter unsere Gewerbe{energyName}-Tarife. Informiere dich jetzt ganz einfach über Gewerbe{energyName} und
          wie du davon profitieren kannst.
        </Text>
      </div>
    </Modal>
  );
};
