import(/* webpackMode: "eager", webpackExports: ["PreloadResources"] */ "/vercel/path0/apps/website/app/preload-resources.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkContextProvider"] */ "/vercel/path0/apps/website/helpers/link-context-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UrlParamsInitializer"] */ "/vercel/path0/apps/website/helpers/UrlParamsInitializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataDogRumIntegration"] */ "/vercel/path0/apps/website/lib/datadog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OptimizelyWrapper"] */ "/vercel/path0/apps/website/lib/optimizely.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledComponentsRegistry"] */ "/vercel/path0/apps/website/lib/styled-components.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/node_modules/next/font/local/target.css?{\"path\":\"../../packages/theme/constants/font-family.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/officina.woff2\",\"adjustFontFallback\":\"Arial\",\"weight\":\"500\",\"variable\":\"--font-headline\"}],\"variableName\":\"headlineFontLoader\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/node_modules/next/font/local/target.css?{\"path\":\"../../packages/theme/constants/font-family.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Inter-roman.var-subset.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Inter-italic.var-subset.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Inter-roman.var-subset.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Inter-italic.var-subset.woff2\",\"weight\":\"600\",\"style\":\"italic\"}],\"variable\":\"--font-text\"}],\"variableName\":\"textFontLoader\"}");
;
import(/* webpackMode: "eager", webpackExports: ["LegacyReactComponentInitializer"] */ "/vercel/path0/packages/cms/helpers/legacy-react-component-initializer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/consent/ConsentManager/ConsentManagerProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/consent/hooks/useConsent.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/consent/hooks/useUsercentricsControllerId.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/tracker/index.tsx");
