'use client';

import { FC } from 'react';

import { IconName } from '@lichtblick/icons';

import { StyledDataItem } from './MDataList.styles';

import { AListItemType } from '../../types/storyblok';
import { ColorKey } from '../../utils';

export type DataListProps = {
  hasDottedLine?: boolean;
  hasTopLine?: boolean;
  icon?: IconName;
  iconColor?: ColorKey;
  isBold?: boolean;
  isLoading?: boolean;
  items: AListItemType[];
  textColor?: ColorKey;
  topLineColor?: ColorKey;
};

export const MDataList: FC<DataListProps> = ({ isBold: isInBoldList, isLoading, items, ...props }) => {
  if (isLoading) {
    return (
      <>
        <StyledDataItem isLoading title="" value="" />
        <StyledDataItem isLoading title="" value="" />
        <StyledDataItem isLoading title="" value="" />
      </>
    );
  }

  return (
    <ul>
      {items.map(({ color, isBold, title, value }) => (
        <StyledDataItem
          {...props}
          color={color}
          isBold={isBold ?? false}
          isInBoldList={isInBoldList}
          key={`${title}+${value}`}
          title={title ?? ''}
          value={value ?? ''}
        />
      ))}
    </ul>
  );
};
