import { FC } from 'react';

import styles from './m-list.module.scss';

import { MListType } from '../../types/storyblok';
import { AListItem, AListItemProps } from '../AListItem/AListItem';

type MListProps = MListType & Pick<AListItemProps, 'colorOverride' | 'textSizeOverride'>;

export const MList: FC<MListProps> = ({ colorOverride, items, textSizeOverride }) => (
  <ul className={styles.list} data-testid="list">
    {items?.map((item) => (
      <AListItem {...item} colorOverride={colorOverride} key={item._uid} textSizeOverride={textSizeOverride} />
    ))}
  </ul>
);
