'use client';

import classNames from 'classnames';
import { FC } from 'react';

import styles from './o-table.module.scss';

import { OTableType } from '../../types/storyblok';
import { MTable } from '../MTable/MTable';
import { MTextIntro } from '../MTextIntro/MTextIntro';

export const OTable: FC<OTableType> = ({ anchorId, table, textIntro, theme }) => (
  <section className={classNames(styles.section, styles[`section-bg-${theme}`])} data-testid="table" id={anchorId}>
    {textIntro?.[0] && (
      <MTextIntro
        {...textIntro[0]}
        buttonVariant="link"
        centeredDesktop
        centeredMobile
        className={classNames(styles['text-intro'])}
      />
    )}
    {table?.[0] && <MTable {...table[0]} />}
  </section>
);
