'use client';
import { FC } from 'react';

import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { NavigationItemProps } from '@lichtblick/photon/types/navigationItemProps';
import { useTracker } from '@lichtblick/tracker';

export const SecondaryNavigationCTA: FC<NavigationItemProps> = ({ href, onClick, text, tracking }) => {
  const { trackCustomEvent } = useTracker();

  return (
    <TextCTA
      as="a"
      href={href}
      onClick={(event) => {
        trackCustomEvent(tracking);
        onClick?.(event);
      }}
      tabIndex={href ? undefined : 0}
    >
      {text}
    </TextCTA>
  );
};
