import classNames from 'classnames';
import { FC } from 'react';

import styles from './AImage.module.scss';
import { Picture } from './Picture';

import { AImageType } from '../../types/storyblok';

export type AImageProps = Omit<AImageType, 'component' | '_uid'> & {
  _uid?: AImageType['_uid'];
  className?: string;
  component?: AImageType['component'];
  isBackground?: boolean;
  loading?: 'eager' | 'lazy';
  maxWidth?: number;
  params?: Record<string, string>;
};

export const AImage: FC<AImageProps> = ({
  altText,
  fullWidth: isFullWidth,
  imageDesktop,
  imageMobile,
  isBackground,
  loading,
  ...props
}) => {
  const description = altText ?? '';

  return (
    <>
      <Picture
        asset={imageMobile}
        className={classNames(
          'picture-mobile',
          Boolean(imageDesktop?.filename) && styles['mobile-is-hidden-on-desktop'],
          isBackground && styles['mobile-is-background'],
          props.className,
        )}
        data-testid="image-mobile"
        description={description}
        isBackground={isBackground}
        isFullWidth={isFullWidth}
        loading={loading}
        maxWidth={props.maxWidth}
      />
      <Picture
        asset={imageDesktop}
        className={classNames(
          'picture-desktop',
          styles.desktop,
          isBackground && styles['mobile-is-background'],
          props.className,
        )}
        data-testid="image-desktop"
        description={description}
        isBackground={isBackground}
        isFullWidth={isFullWidth}
        loading={loading}
        maxWidth={props.maxWidth}
      />
    </>
  );
};
