'use client';

import { FC, KeyboardEventHandler, MouseEventHandler, useRef, useState } from 'react';

import { generateUuid } from '@lichtblick/localization';
import { Headline } from '@lichtblick/photon/atoms/headline/headline';

import styles from './slider.module.scss';

import { MTeaserItemType } from '../../types';
import { MTeaserItem } from '../MTeaserItem';

type CarouselProps = {
  items: MTeaserItemType[];
};

export const Slider: FC<CarouselProps> = ({ items }) => {
  const [isPreviousControlEnabled, setIsPreviousControlEnabled] = useState(false);
  const [isNextControlEnabled, setIsNextControlEnabled] = useState(true);
  const skipCarouselAnchorMarkId = generateUuid().toString();
  const previousControlRef = useRef<HTMLButtonElement>(null);
  const nextControlRef = useRef<HTMLButtonElement>(null);
  const slidesRef = useRef<HTMLDivElement>(null);
  const slideItemsRef = useRef<HTMLDivElement[]>([]);

  const handleClick: MouseEventHandler = (event) => {
    const itemWidth = slideItemsRef.current[0]?.clientWidth ?? 0;

    slidesRef.current?.scrollBy({
      left: event.target === nextControlRef.current ? itemWidth : -itemWidth,
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (previousControlRef.current === event?.target) {
        previousControlRef.current?.click();
      } else {
        nextControlRef.current?.click();
      }
    }
  };

  const enableControls = () => {
    const scrollLeft = slidesRef.current?.scrollLeft ?? 0;
    const clientWidth = slidesRef.current?.clientWidth ?? 0;
    const scrollWidth = slidesRef.current?.scrollWidth ?? 0;

    if (scrollLeft === 0) {
      setIsPreviousControlEnabled(false);
    } else {
      setIsPreviousControlEnabled(true);
    }

    if (clientWidth === scrollWidth - scrollLeft) {
      setIsNextControlEnabled(false);
    } else {
      setIsNextControlEnabled(true);
    }
  };

  return (
    <div className={styles.slider}>
      <a className={styles['skip-link']} href={`#${skipCarouselAnchorMarkId}`}>
        <Headline size="m">Carousel überspringen</Headline>
      </a>
      {items.length > 4 && (
        <div className={styles.controls}>
          <button
            aria-disabled={!isPreviousControlEnabled}
            aria-label="Pfeil links"
            className={styles.control}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            ref={previousControlRef}
            tabIndex={0}
          >
            <div className={styles['icon-prev']} />
          </button>
          <button
            aria-disabled={!isNextControlEnabled}
            aria-label="Pfeil rechts"
            className={styles.control}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            ref={nextControlRef}
            tabIndex={0}
          >
            <div className={styles['icon-next']} />
          </button>
        </div>
      )}
      <div className={styles.slides} onScroll={enableControls} ref={slidesRef}>
        {items.map((item, index) => (
          <div
            className={styles['slide-item']}
            key={item._uid}
            ref={(element) => {
              if (element) {
                slideItemsRef.current[index] = element;
              }
            }}
          >
            <MTeaserItem className={styles['teaser-item']} {...item} accentColor={'black'} isTextOutside />
          </div>
        ))}
      </div>
      <span id={skipCarouselAnchorMarkId} />
    </div>
  );
};
