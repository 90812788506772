'use client';

import classNames from 'classnames';
import Script from 'next/script';
import { FC, useState } from 'react';

import { Loader } from '@lichtblick/ui-components';

import styles from './gapminder.module.scss';

import { AppConfigType } from '../../../types/storyblok';

export const Gapminder: FC<AppConfigType> = ({ objects }) => {
  const gapminderAssetsPath = 'https://static.lichtblick.de/snowflakes/nextjs/gapminder';
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const parsedObjects = typeof objects === 'object' ? objects : objects && JSON.parse(objects);

  return (
    <>
      <Script
        onReady={() => {
          setIsLoading(false);
          document.dispatchEvent(new Event('initSnowflakeGapminder'));
        }}
        src={`${gapminderAssetsPath}/main.59a9f71570beec099f0a692330de5029.js`}
      />
      <link
        href={`${gapminderAssetsPath}/styles.59a9f71570beec099f0a692330de5029.css`}
        key="gapminder-snowflake-css"
        rel="stylesheet"
      />
      <link
        href="https://static.lichtblick.de/react-components/hugo-apps.css"
        key="hugo-apps-styles"
        rel="stylesheet"
      />
      <div
        className={classNames(
          'snowflake-gapminder',
          'gapminder',
          'theme--faded-gray',
          'theme--light',
          styles.container,
        )}
        id="gapminder"
      >
        {isLoading && (
          <div className={styles['loader-container']}>
            <Loader />
          </div>
        )}
        <div className="slides space__ptd--xl space__ptm--m">
          {parsedObjects?.slides.map((slide: any, i: number) => (
            <div className={`slide${i > 0 ? ' right' : ''}`} id={`slide${i}`} key={slide.question + slide.answer}>
              <div className="gapminder-inner">
                <div className="icon" id="gapminder_icon">
                  {/* eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element */}
                  <img src={`${gapminderAssetsPath}${slide.icon}`} />
                </div>
                <div className="a-chip accent__bg">Was denkst du?</div>
                <h2
                  className="a-headline a-headline--m question space__pbd--s space__pbm--s"
                  data-error={slide.answer_error}
                  data-success={slide.answer_success}
                >
                  {slide.question}
                </h2>
                <p className="space__pbd--s space__pbm--s" dangerouslySetInnerHTML={{ __html: slide.answer }} />

                {Boolean(slide.answer) && (
                  <>
                    <div className="answered space__pbd--l space__pbm--m">
                      <div className="button" data-answer="0" data-result={slide.result_1}>
                        {slide.button_1}
                      </div>
                      <div className="button" data-answer="1" data-result={slide.result_2}>
                        {slide.button_2}
                      </div>
                      <div className="button" data-answer="2" data-result={slide.result_3}>
                        {slide.button_3}
                      </div>
                    </div>
                    <button
                      className="a-button a-button--link gapminder_next space__pbd--xl space__pbm--m"
                      type="button"
                    >
                      <span>Nächste Frage</span>
                    </button>
                  </>
                )}
                {!slide.answer && (
                  <div className="answers">
                    <button className="a-button a-button--outline space__pbm--s" data-answer="0" type="button">
                      <span>{slide.button_1}</span>
                    </button>
                    <button className="a-button a-button--outline space__pbm--s" data-answer="1" type="button">
                      <span>{slide.button_2}</span>
                    </button>
                    <button className="a-button a-button--outline space__pbm--s" data-answer="2" type="button">
                      <span>{slide.button_3}</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
