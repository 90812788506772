import { ChangeEventHandler, FC, FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { logger } from '@lichtblick/logger';
import { ComboBoxInput } from '@lichtblick/photon/molecules/combo-box-input/combo-box-input';
import { useTracker } from '@lichtblick/tracker';

import { CalculatorTranslations } from './translations';

export type Cities = { cityId: number; name: string }[];

const getCities = async (postcode: string): Promise<Cities | undefined> => {
  let retryCount = 3;

  if (!/^\d{5}$/.exec(postcode)) {
    return [];
  }

  while (retryCount > 0) {
    let response: Response | undefined = undefined;

    try {
      response = await fetch(`/api/zip/${postcode}/`);
    } catch (e) {
      // handled via retryCounter
    }

    if (response?.status === 200) {
      try {
        return await response.json();
      } catch (e) {
        logger.warn('[AIOC] Failed parsing cities JSON');
      }
    }

    retryCount--;

    if (retryCount === 0) {
      logger.log('[AIOC] Failed to fetch cities');
    }

    await new Promise((resolve) => setTimeout(resolve, 500));
  }

  return undefined;
};

type CityInputProps = {
  onChange: (city: string, postcode: string) => void;
};

export const CityInput: FC<CityInputProps> = ({ onChange }) => {
  const { trackCustomEvent } = useTracker();
  const [isMissingValue, setIsMissingValue] = useState(false);
  const [isFocussed, setIsFocussed] = useState(false);

  const [cities, setCities] = useState<Cities | null>(null);
  const [city, setCity] = useState<Cities[number] | null>(null);
  const [postcode, setPostcode] = useState<string | null>(null);

  const isRefocusRef = useRef<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (city && postcode) {
      onChange(`${city.cityId}_${city.name}`, postcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, postcode]);

  useEffect(() => {
    if (city) {
      inputRef.current?.setCustomValidity('');
    } else {
      inputRef.current?.setCustomValidity(CalculatorTranslations.invalid);
    }
  }, [city]);

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    if (!isRefocusRef.current && inputRef.current) {
      inputRef.current.value = inputRef.current.value.substring(0, 5);
    }

    isRefocusRef.current = false;
  }, []);

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    async (e) => {
      setIsFocussed(false);
      setIsMissingValue(false);

      // e.relatedTarget === 'li' if click target is selectable element
      if (e.relatedTarget?.localName === 'li') {
        return;
      }

      if (inputRef.current && city && postcode) {
        inputRef.current.value = `${postcode} ${city.name}`;

        return;
      }

      if (inputRef.current?.value?.length === 5) {
        const inputValue = inputRef.current.value;

        const citiesByPostcode = (await getCities(inputValue)) || [];

        if (!citiesByPostcode.length) {
          if (inputRef.current) {
            inputRef.current.value = '';
          }

          setIsMissingValue(true);

          return;
        }

        setPostcode(inputValue);
        setCities(citiesByPostcode);
        setCity(citiesByPostcode[0]);

        if (!inputRef.current) {
          return;
        }

        inputRef.current.value = `${inputValue} ${citiesByPostcode[0].name}`;

        return;
      }

      if (inputRef.current) {
        inputRef.current.value = '';
        setIsMissingValue(true);
      }
    },
    [city, postcode],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      // new city selected from list
      if (!e.type && inputRef.current) {
        inputRef.current.value = e.target.value;
        isRefocusRef.current = true;

        const city = cities?.find((item) => `${postcode} ${item.name}` === inputRef?.current?.value);

        setCity(city ?? null);
        setIsMissingValue(!city);

        setTimeout(() => {
          inputRef.current?.blur();
        }, 0);

        return;
      }

      const inputValue = e.currentTarget?.value;

      if (inputValue.length === 5) {
        const citiesByPostcode = (await getCities(inputValue)) || [];

        setPostcode(inputValue);
        setCities(citiesByPostcode);
        setCity(citiesByPostcode[0]);

        return;
      }

      if (inputValue.length === 0 || !postcode?.startsWith(inputValue)) {
        setCities(null);
        setCity(null);
      }
    },

    [cities, isRefocusRef, postcode],
  );

  return (
    <ComboBoxInput
      autoComplete="off"
      label="Postleitzahl"
      maxLength={5}
      name="postcode"
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
      onFocus={handleFocus}
      onFocusCapture={() => {
        setIsFocussed(true);
      }}
      onInvalid={(e) => {
        e.preventDefault();
        setIsMissingValue(!e.currentTarget.value);
      }}
      options={cities?.map(({ name }) => ({ value: `${postcode} ${name}` })) ?? []}
      ref={inputRef}
      required
      statusLabel={isMissingValue ? CalculatorTranslations.missing : undefined}
      statusType={!isFocussed && isMissingValue ? 'error' : undefined}
    />
  );
};
