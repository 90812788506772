import classNames from 'classnames';
import { FC } from 'react';

import { Chip } from '@lichtblick/photon/atoms/chip/chip';
import { Headline, HeadlineProps } from '@lichtblick/photon/atoms/headline/headline';
import { Text, TextProps } from '@lichtblick/photon/atoms/text/text';

import styles from './m-text-intro.module.scss';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { MTextIntroType } from '../../types/storyblok';
import { ColorKey, photonColorMap } from '../../utils/colors';
import { AButton, AButtonProps } from '../AButton';

export type MTextIntroProps = MTextIntroType & {
  accentColor?: ColorKey;
  buttonVariant?: AButtonProps['variant'];
  centeredDesktop?: boolean;
  centeredMobile?: boolean;
  className?: string;
  headlineProps?: HeadlineProps;
  textProps?: TextProps;
};

const elementMappers: Partial<ElementMappers> = {
  h1: ({ children }) => (
    <Headline className={styles['rich-text-headline']} renderAs="h1" size="s">
      {children}
    </Headline>
  ),
  h3: ({ children }) => (
    <Headline className={styles['rich-text-headline']} renderAs="h3" size="s">
      {children}
    </Headline>
  ),
  p: ({ children }) => (
    <Text renderAs="p" size="m">
      {children}
    </Text>
  ),
};

export const MTextIntro: FC<MTextIntroProps> = ({
  accentColor = 'black',
  button,
  buttonVariant = 'link',
  centeredDesktop,
  centeredMobile,
  chip,
  className,
  headline,
  headlineProps,
  richText,
  textProps,
}) => (
  <div
    className={classNames(
      centeredMobile && styles['text-intro-centered-mobile'],
      centeredDesktop && styles['text-intro-centered-desktop'],
      className,
    )}
    data-testid="text-intro"
  >
    {chip && (
      <Chip backgroundColor={photonColorMap[accentColor]} className={styles.chip}>
        {chip}
      </Chip>
    )}
    {Boolean(headline) && (
      <Headline renderAs="h2" {...headlineProps} className={styles.headline}>
        {headline}
      </Headline>
    )}
    {richText && <Text {...textProps}>{<RichText elementMappers={elementMappers} richText={richText} />}</Text>}
    {button?.[0] && <AButton {...button[0]} className={styles.button} variant={buttonVariant} />}
  </div>
);
