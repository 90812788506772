import { ComponentPropsWithRef, useEffect, useState } from 'react';

import { Textfield as InnerTextfield } from '@lichtblick/ui-components/molecules/Textfield/Textfield';

type TextfieldProps = Omit<
  ComponentPropsWithRef<typeof InnerTextfield>,
  'value' | 'onBlur' | 'onFocus' | 'onInvalid' | 'statusLabel'
> & {
  forcedError?: string;
};

export const Textfield: React.FC<TextfieldProps> = (props) => {
  const [invalidValue, setInvalidValue] = useState(false);
  const [missingValue, setMissingValue] = useState(false);
  const [focussed, setFocussed] = useState(false);

  const [forcedError, setForcedError] = useState(props.forcedError ?? undefined);

  useEffect(() => {
    setForcedError(props.forcedError ?? undefined);
  }, [setForcedError, props.forcedError]);

  return (
    <InnerTextfield
      {...props}
      onBlur={(e) => {
        setFocussed(false);
        setInvalidValue(!e.target.validity.valid && !e.target.validity.valueMissing);
        setMissingValue(e.target.validity.valueMissing);
      }}
      onChange={(event) => {
        setForcedError(undefined);
        setInvalidValue(false);
        props.onChange?.(event);
      }}
      onFocus={() => {
        setFocussed(true);
      }}
      onInvalid={(e) => {
        e.preventDefault();
        setInvalidValue(!e.currentTarget.validity.valid && !e.currentTarget.validity.valueMissing);
        setMissingValue(e.currentTarget.validity.valueMissing);
      }}
      statusLabel={
        missingValue
          ? 'Hier fehlt noch eine Angabe'
          : invalidValue || forcedError
            ? 'Hier stimmt was nicht.'
            : undefined
      }
      statusType={!focussed && (invalidValue || missingValue || forcedError) ? 'error' : 'default'}
    />
  );
};
