'use client';

import { ChangeEvent, FC } from 'react';

import { toIconName } from '@lichtblick/icons';
import { Colors } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';

import {
  ButtonPlaceholder,
  Description,
  DescriptionPlaceholder,
  HeadlinePlaceholder,
  HeadlineWrapper,
  ProductCardTeaser,
  StyledHeadline,
  StyledIcon,
  Trademark,
} from './MProductCardTeaser.styles';

import { MProductCardTeaserType } from '../../types/storyblok';
import { AButton } from '../AButton';
import { AToggle } from '../AToggle';

export type ProductCardTeaserProps = MProductCardTeaserType & {
  isLoading?: boolean;
  isToggleEnabled?: boolean;
  onToggleClick?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const MProductCardTeaser: FC<ProductCardTeaserProps> = ({
  headline,
  icon,
  infoDialogButton,
  isLoading,
  isToggleEnabled = false,
  onToggleClick,
  paragraph,
  toggle,
  trademark,
}) => {
  if (isLoading) {
    return (
      <ProductCardTeaser id="product-card-teaser">
        <HeadlinePlaceholder />
        <DescriptionPlaceholder />
        <ButtonPlaceholder />
      </ProductCardTeaser>
    );
  }

  return (
    <ProductCardTeaser id="product-card-teaser">
      {trademark && (
        <Trademark $isToggleEnabled={isToggleEnabled}>
          <StyledIcon $color={Colors.Smoke} name="PlusIconS"></StyledIcon>
        </Trademark>
      )}
      <HeadlineWrapper>
        {icon && <StyledIcon name={toIconName(icon)}></StyledIcon>}
        {headline && (
          <StyledHeadline renderAs={'h3'} size="S">
            {headline}
          </StyledHeadline>
        )}
      </HeadlineWrapper>
      {paragraph && (
        <Description>
          <Text renderAs={'span'} size="Xs">
            {paragraph}
            {infoDialogButton?.[0] && <AButton {...infoDialogButton[0]} isSlim variant="link" />}
          </Text>
        </Description>
      )}
      {toggle?.[0] && (
        <AToggle
          {...toggle[0]}
          isChecked={isToggleEnabled}
          name={`${headline}-toggle`}
          onClick={onToggleClick}
          value={`${headline}-toggle`}
        />
      )}
    </ProductCardTeaser>
  );
};
