'use client';

import { FC, useActionState, useEffect, useState } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { transient } from '@lichtblick/styled';
import { useTracker } from '@lichtblick/tracker';
import { BoxCTA, Container, StatusMessage, Text } from '@lichtblick/ui-components';

import { submitForm } from './actions';
import { ButtonWrapper, Controls, FunnelForm, Root, StageCard, StyledSearchIcon } from './SoftFunnelEntry.styles';
import { Textfield } from './Textfield';

import { SoftFunnelEntryType } from '../../../types';

const message = `Leider kein Installationspartner in deiner Nähe gefunden. Werde benachrichtigt, sobald das SolarPaket \
in deiner Region verfügbar ist.`;

export const SoftFunnelEntryCard: FC<Pick<SoftFunnelEntryType, 'theme' | 'title'>> = ({ theme, title }) => {
  const [state, dispatch, pending] = useActionState(submitForm, {
    partnerAvailable: undefined,
    zipCode: '',
  });
  const [currentZip, setCurrentZip] = useState('');

  const { trackCustomEvent } = useTracker();

  const isParkedLeadCase = state.partnerAvailable === false && currentZip === state.zipCode;

  useEffect(() => {
    if (state.partnerAvailable) {
      location.assign(`/zuhause-checkout?buildingPostalCode=${state.zipCode}&page=HouseTypePage`);
    }
  }, [state]);

  return (
    <StageCard $theme={theme}>
      <FunnelForm
        action={dispatch}
        onSubmit={() => {
          trackCustomEvent({ eventName: 'eaas_soft_funnel_cta' });
        }}
      >
        <Text isBold>{title || 'Wo soll Deine SolarAnlage installiert werden?'}</Text>
        <Controls>
          <Textfield
            defaultValue={state.zipCode}
            forcedError={state.errorMessage}
            leftSlot={<StyledSearchIcon />}
            name="zipCode"
            onChange={({ target }) => setCurrentZip(target.value)}
            pattern="\d{5}"
            required
          />
          <ButtonWrapper>
            {isParkedLeadCase ? (
              <BoxCTA
                as="a"
                href={`/zuhause-checkout?buildingPostalCode=${state.zipCode}&page=UnsupportedRegionPage`}
                variant="secondary"
              >
                Benachrichtigt mich
              </BoxCTA>
            ) : (
              <BoxCTA as="button" isLoading={pending} isWide variant="primary">
                SolarAnlage berechnen
              </BoxCTA>
            )}
          </ButtonWrapper>
        </Controls>
        {isParkedLeadCase && <StatusMessage icon={<InfoIconXs />} message={message} status="action" type="outline" />}
      </FunnelForm>
    </StageCard>
  );
};

export const SoftFunnelEntry: FC<SoftFunnelEntryType> = (props) => {
  return (
    <Root {...transient(props)}>
      <Container as="div">
        <SoftFunnelEntryCard {...props} />
      </Container>
    </Root>
  );
};
