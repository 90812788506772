'use client';

import { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

import { CtaContentWrapper, CtaImage, CtaText } from './SolarCheckoutTeaser.styles';

import { SolarCheckoutTeaserType } from '../../../types';
import { getColor } from '../../../utils';
import { Section } from '../shared';

export const SolarCheckoutTeaser: FC<SolarCheckoutTeaserType> = ({ image, textIntro, theme = 'white' }) => {
  return (
    <Section backgroundColor={getColor(theme)}>
      <Container as="div">
        <CtaContentWrapper $backgroundColor={theme === 'white' ? Colors.FadedGray : Colors.White}>
          {image?.[0] && <CtaImage {...image[0]} />}
          {textIntro?.[0] && <CtaText {...textIntro[0]} />}
        </CtaContentWrapper>
      </Container>
    </Section>
  );
};
