import { FC } from 'react';

import styles from './anchor-mark.module.scss';

type AnchorMarkProps = {
  anchorId: string;
  offset?: number;
};

export const AnchorMark: FC<AnchorMarkProps> = ({ anchorId, offset }) => (
  <span className={styles['anchor-mark']} id={anchorId} style={{ top: offset }} />
);
