import styled from '@lichtblick/styled';
import { MediaDesktop, Spacing } from '@lichtblick/theme';
import { ContainerStyle, StyledTextIntro } from '@lichtblick/ui-components';

export const OptionalComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
  justify-content: space-evenly;
  ${MediaDesktop} {
    flex-direction: row;
  }
`;

export const ContainerColumn = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
  ${ContainerStyle}
  padding-bottom: ${Spacing.Xl};
  padding-top: ${Spacing.Xl};

  & > ${StyledTextIntro} {
    max-width: 37rem;
  }
`;
