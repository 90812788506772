import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import { ArrowRightIconXs } from '@lichtblick/icons/svg/arrow-right/arrow-right-xs';
import { ChevronDownIconS } from '@lichtblick/icons/svg/chevron-down/chevron-down-s';
import { ChevronUpIconS } from '@lichtblick/icons/svg/chevron-up/chevron-up-s';
import { Text } from '@lichtblick/photon/atoms/text/text';

import styles from './sticky-nav.module.scss';

import { MIndexItemType } from '../../types';

const useScrollLock = (isExpanded: boolean) => {
  useEffect(() => {
    const enableScrollLock = () => {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    };

    const disableScrollLock = () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };

    if (isExpanded) {
      enableScrollLock();
    } else {
      disableScrollLock();
    }

    return () => {
      disableScrollLock();
    };
  }, [isExpanded]);
};

export const StickyNav: FC<{ items: MIndexItemType[]; visible: boolean }> = ({ items, visible }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useScrollLock(isExpanded);

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);

  const handleMouseEnter = () => {
    if (isTouchDevice) {
      return;
    }

    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    if (isTouchDevice) {
      return;
    }

    setIsExpanded(false);
  };

  return (
    <>
      <div aria-hidden="true" className={classNames(styles.nav, visible && styles.visible)}>
        <button
          className={styles.toggle}
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          tabIndex={-1}
        >
          <Text bold size="m">
            Inhaltsangabe
          </Text>
          {isExpanded ? <ChevronUpIconS /> : <ChevronDownIconS />}
        </button>
        <ul
          className={classNames(styles.flyout, isExpanded && styles.expanded)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {items.map((item) => (
            <li key={item._uid}>
              <a href={item.linkUrl} onClick={() => setIsExpanded((prev) => !prev)} tabIndex={-1}>
                <ArrowRightIconXs className={styles['flyout-link-icon']} />
                {item.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div
        aria-hidden="true"
        className={classNames(styles.overlay, isExpanded && styles.expanded)}
        onClick={() => setIsExpanded((prev) => !prev)}
        tabIndex={-1}
      />
    </>
  );
};
