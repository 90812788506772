'use client';

import { createInstance, OptimizelyProvider, ReactSDKClient } from '@optimizely/react-sdk';
import { FC, PropsWithChildren } from 'react';

import { OPTIMIZELY_NAME } from '@lichtblick/consent/ConsentManager/ConsentManager.types';
import { useConsent } from '@lichtblick/consent/hooks/useConsent';
import { useUsercentricsControllerId } from '@lichtblick/consent/hooks/useUsercentricsControllerId';

export const OptimizelyWrapper: FC<PropsWithChildren> = ({ children }) => {
  const ucControllerId = useUsercentricsControllerId() || null;
  const optimizelyConsentState = useConsent(OPTIMIZELY_NAME);

  const optimizelyInstance =
    typeof window !== 'undefined'
      ? createInstance({
          sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
          logLevel: 'error',
          odpOptions: { disabled: true },
        })
      : (null as unknown as ReactSDKClient);

  return (
    <OptimizelyProvider optimizely={optimizelyInstance} user={{ id: optimizelyConsentState ? ucControllerId : null }}>
      {children}
    </OptimizelyProvider>
  );
};
