'use client';

import { FC } from 'react';

import { BoxCTA, Chip, LegacyLink, Text } from '@lichtblick/ui-components';

import {
  Badge,
  Body,
  Card,
  Container,
  Footer,
  FooterText,
  Header,
  StyledAccentText,
  StyledHeadline,
  StyledLinkText,
  StyledTagline,
  StyledText,
  TariffIcon,
} from './MTariffCard.styles';

import { getHref } from '../../helpers';
import { ElementMappers, RichText } from '../../helpers/RichText';
import { MTariffCardType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { AButton } from '../AButton';
import { AImage } from '../AImage/AImage';
import { MDataList } from '../MDataList';

export type MTariffCardProps = MTariffCardType & {
  onClick?: () => void;
};

const elementMappers: Partial<ElementMappers> = {
  p: ({ children }) => <Text size="Xs">{children}</Text>,
  a: ({ children, href }) => (
    <StyledLinkText size="xs">
      <LegacyLink href={href ?? '#'}>{children}</LegacyLink>
    </StyledLinkText>
  ),
};

export const MTariffCard: FC<MTariffCardProps> = ({
  badge,
  button,
  chip,
  chipColor,
  footerText,
  footerTextColor,
  headline,
  icon,
  infoDialogButton,
  onClick,
  price,
  priceColor,
  primaryList,
  secondaryLists,
  subheadline,
  tagline,
  theme,
}) => {
  return (
    <Card $themeColor={getColor(theme) ?? undefined} data-tariff-name={headline} data-testid="tariff-card">
      {badge && (
        <Badge>
          <Text size="S">{badge}</Text>
        </Badge>
      )}
      <Header>
        <StyledTagline size="xs">{tagline ?? ''}</StyledTagline>
        <StyledHeadline renderAs="h3" size="S">
          {headline ?? ''}
        </StyledHeadline>
      </Header>
      <Body>
        <TariffIcon>{Boolean(icon?.[0]) && <AImage {...icon[0]} />}</TariffIcon>
        <Container>
          <StyledText bold size="xs">
            {subheadline ?? ''}
          </StyledText>
          <StyledAccentText $color={getColor(priceColor) ?? 'black'} size="L">
            {price ?? ''}
          </StyledAccentText>

          {infoDialogButton?.[0] && <AButton {...infoDialogButton[0]} isSlim variant="link" />}

          {chip && <Chip backgroundColor={getColor(chipColor) ?? getColor(theme)} label={chip} />}
        </Container>
        {primaryList?.[0]?.items && (
          <MDataList hasTopLine icon="CheckIconXs" isBold items={primaryList[0].items} topLineColor={theme} />
        )}
      </Body>
      <Footer>
        {secondaryLists &&
          secondaryLists.length > 0 &&
          secondaryLists.map((item) => (
            <MDataList
              hasDottedLine
              icon="CheckIconXs"
              iconColor={'light-moss'}
              items={item?.items ?? []}
              key={`m-tariff-card-m-data-list-${item._uid}`}
            />
          ))}
        {footerText && (
          <FooterText $color={getColor(footerTextColor)}>
            <RichText elementMappers={elementMappers} richText={footerText} />
          </FooterText>
        )}
        {button?.[0] && (
          <BoxCTA as="a" href={getHref(button[0])} onClick={onClick}>
            {button[0].text}
          </BoxCTA>
        )}
      </Footer>
    </Card>
  );
};
