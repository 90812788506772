'use client';

import ReactDOM from 'react-dom';

export const PreloadResources = () => {
  ReactDOM.preconnect('https://www.googletagmanager.com', { crossOrigin: 'anonymous' });
  ReactDOM.preconnect('https://a.storyblok.com', { crossOrigin: 'anonymous' });

  return null;
};
