'use client';
import { FC } from 'react';

import { TextCTA } from '@lichtblick/photon/atoms/text-cta/text-cta';
import { NavigationItemProps } from '@lichtblick/photon/types/navigationItemProps';
import { useTracker } from '@lichtblick/tracker';

import styles from './Footer.module.scss';

export const PrimarySubNavigation: FC<NavigationItemProps> = ({ href, shouldOpenInNewTab, text, tracking }) => {
  const { trackCustomEvent } = useTracker();

  return (
    <li className={styles['primary-nav-item']}>
      <TextCTA
        as="a"
        href={href}
        onClick={() => trackCustomEvent(tracking)}
        tabIndex={href ? undefined : 0}
        target={shouldOpenInNewTab ? '_blank' : ''}
      >
        {text}
      </TextCTA>
    </li>
  );
};
