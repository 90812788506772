'use client';

import { FC, MouseEventHandler, PropsWithChildren } from 'react';

import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';

const openUsercentricsSecondLayer: MouseEventHandler<Element> = (event) => {
  event.preventDefault();
  event.stopPropagation();
  window.__ucCmp?.showSecondLayer();
};

export const PrivacyButton: FC<PropsWithChildren> = ({ children }) => (
  <BoxCTA as="button" onClick={openUsercentricsSecondLayer} theme="dark" variant="neutral">
    {children}
  </BoxCTA>
);
