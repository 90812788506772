'use client';

import classNames from 'classnames';
import { FC, PropsWithChildren, use, useEffect, useRef } from 'react';

import styles from './m-road-to-zero-item.module.scss';

import { MRoadToZeroItemType } from '../../types/storyblok';
import { ProgressContext } from '../ORoadToZero/progress-context-wrapper';

type SectionWithIntersectionHandlingProps = PropsWithChildren &
  Pick<MRoadToZeroItemType, 'anchorId' | 'progressValue' | 'theme'>;

export const SectionWithScrollHandling: FC<SectionWithIntersectionHandlingProps> = ({
  anchorId,
  children,
  progressValue,
  theme,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const state = use(ProgressContext);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      ([entry]) => entry.intersectionRatio >= 0.5 && state?.[1](Number(progressValue)),
      {
        threshold: 0.5,
      },
    );

    if (ref.current) {
      intersectionObserver.observe(ref.current);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [state, ref, progressValue]);

  return (
    <section className={classNames(styles.section, styles[`section-bg-${theme}`])} id={anchorId} ref={ref}>
      {children}
    </section>
  );
};
