'use client';

import styled, { css } from '@lichtblick/styled';
import { Colors, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { Box, StyledTextCTA } from '@lichtblick/ui-components';

import { AText } from '../AText/AText';

export const contentStyles = css`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Root = styled.div`
  background: ${Colors.Black};
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
`;

export const DataPrivacyLayer = styled(Box).attrs({ backgroundColor: Colors.Black })`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${contentStyles};
  padding: ${Spacing.Xs} ${Spacing.Xxs};
  text-align: center;

  ${StyledTextCTA} {
    margin: ${Spacing.Xxs};
  }
`;

export const PrivacyText = styled(AText).attrs({ size: 'xs' })`
  margin: 0 0 ${Spacing.Xxs};

  ${MediaSmallAndAbove} {
    margin: ${Spacing.Xxs} 0 ${Spacing.Xs};
  }

  max-width: 23rem;
`;

export const Iframe = styled.iframe.attrs({
  allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
  allowFullScreen: true,
  loading: 'lazy',
  title: 'YouTube video player',
})`
  border: 0;
  ${contentStyles}
`;
