'use client';
import { FC, PropsWithChildren, useEffect } from 'react';

import { DATADOG_RUM_NAME } from '@lichtblick/consent/ConsentManager/ConsentManager.types';
import { useConsent } from '@lichtblick/consent/hooks/useConsent';
import { logger } from '@lichtblick/logger';

export const DataDogRumIntegration: FC<PropsWithChildren> = ({ children }) => {
  const datadogRumState = useConsent(DATADOG_RUM_NAME);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

    if (!datadogRumState) {
      return;
    }

    import('@datadog/browser-rum').then(({ datadogRum }) => {
      try {
        if (!datadogRum.getInitConfiguration() && applicationId && clientToken) {
          datadogRum.init({
            applicationId,
            clientToken,
            site: 'datadoghq.eu',
            service: process.env.NEXT_PUBLIC_DATADOG_SERVICE_NAME || 'frontend-next',
            env: process.env.NEXT_PUBLIC_STAGE,
            version: process.env.NEXT_PUBLIC_GIT_COMMIT_REF,
            sessionReplaySampleRate: 100,
            startSessionReplayRecordingManually: true,
            sessionSampleRate: 80,
            storeContextsAcrossPages: true,
            trackUserInteractions: true,
            trackResources: true,
            allowedTracingUrls: [/https:\/\/graph(tst|dev|)\.lichtblick\.de.*/],
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            beforeSend: (event) => {
              if (!datadogRumState && event.type !== 'view') {
                return false;
              }

              if (
                event.type === 'error' &&
                (event.error.stack?.includes('hubspot.com/web-interactives-embed.js') ||
                  event.error.message.includes('[OPTIMIZELY]') ||
                  event.error.message === 'Error: Failed to load static props')
              ) {
                return false;
              }

              return true;
            },
          });
        }
      } catch (error) {
        logger.error('datadog RUM error', { error });
      }
    });
  }, [datadogRumState]);

  return children;
};
