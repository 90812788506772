import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { LottiePlayer } from './lottie-player';
import styles from './m-animated-illustration.module.scss';

import { MAnimatedIllustrationType } from '../../types/storyblok';

export const MAnimatedIllustration: FC<
  MAnimatedIllustrationType & { className?: string; loading?: 'eager' | 'lazy' }
> = ({
  className,
  disableMaxWidth = false,
  height,
  illustration,
  loading = 'lazy',
  lottieAnimationData,
  width,
  ...props
}) => (
  <>
    {lottieAnimationData?.filename && (
      <LottiePlayer
        {...props}
        className={classNames(className, disableMaxWidth && styles['max-width'])}
        data-testid="animated-illustration"
        height={height}
        lottieAnimationData={lottieAnimationData}
        width={width}
      />
    )}
    {illustration && !lottieAnimationData && (
      <Image
        alt=""
        className={classNames(className, styles.svg, disableMaxWidth && styles['max-width'])}
        height={height ? Number(height) : 1}
        loading={loading}
        priority={loading === 'eager'}
        src={`/assets/illustrations/${illustration}.svg`}
        width={width ? Number(width) : 1}
      />
    )}
  </>
);
