import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { ContainerStyle, Headline } from '@lichtblick/ui-components';

import { getColumnWidth } from '../../shared';

export const Diagram = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    height: 100%;
    width: 100%;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.M};
  ${ContainerStyle}

  ${MediaSmallAndAbove} {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 20rem;
  margin-inline: auto;
`;

export const SvgWrapper = styled.div`
  width: 100%;
`;

export const StyledHeadline = styled(Headline)`
  ${MediaMediumAndAbove} {
    max-width: calc(${getColumnWidth(11)} - 2rem);
  }

  &:not(:last-child) {
    margin-bottom: ${Spacing.Xxs};
  }
`;
