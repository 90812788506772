'use client';

import { Icon as IconBase } from '@lichtblick/icons';
import styled from '@lichtblick/styled';
import { BorderRadius, BorderWidth, Colors, Duration, DurationAndEasing, Spacing } from '@lichtblick/theme';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@lichtblick/ui-components';

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Icon = styled(IconBase)`
  margin-left: 0.25rem;
  transition: transform ${DurationAndEasing.LargeOutro};

  ${AccordionTrigger}[data-state='open'] & {
    transform: rotate(45deg);
  }
`;

export const IconWrapper = styled.div`
  line-height: 0;
  width: 2.5rem;
  flex-shrink: 0;
`;

export const CollapsibleContent = styled.div`
  padding: 0 0 ${Spacing.Xs} ${Spacing.L};
`;

export const Root = styled(AccordionItem)<{ $isThemeFadedGray: boolean }>`
  border-color: ${({ $isThemeFadedGray }) => ($isThemeFadedGray ? Colors.White : Colors.FadedGray)};
  border-style: solid;
  border-width: ${BorderWidth.Input} 0 0 0;

  &:last-child {
    border-bottom-width: ${BorderWidth.Input};
  }

  ${AccordionTrigger} {
    padding: ${Spacing.Xxs} ${Spacing['3Xs']};
    margin: ${Spacing['3Xs']} 0;
    border-radius: ${BorderRadius.S};

    &:active {
      background: color-mix(in srgb, ${Colors.Black} 11%, transparent);
    }
  }

  @media (hover: hover) {
    & ${AccordionTrigger}:hover {
      background: color-mix(in srgb, ${Colors.Black} 11%, transparent);
    }
  }
`;

export const Content = styled(AccordionContent)`
  &[data-state='open'],
  &[data-state='closed'] {
    animation-duration: ${Duration.SmallOutro}ms;
    animation-fill-mode: forwards;
  }

  &[data-state='open'] {
    animation-timing-function: ease-in;
  }
  &[data-state='closed'] {
    animation-timing-function: ease-out;
  }
`;
